.sidebar-item.logout-btn {
	padding: 0;
	margin: 0;
}
#logout-modal {
    .modal-dialog{
        width: 400px;
    }
	.modal-content {
		.modal-header {
			.modal-title {
				margin: 0 auto;
                font-size: 18px;
			}
			button {
				display: none;
			}
		}
        .text{
            text-align: center;
            margin-top: 15px;
            font-size: 16px;
            margin-bottom: 0;
            color: #7A7A7A;
        }
		.modal-body {
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            button {
                border: 1px solid #f3d127;
				background: #fff;
				color: #000;
				border-radius: 10px;
                width: 150px;
				height: 44px;
                font-size: 14px;
				margin-top: 14px;
                transition: 0.3s all;
                &.yes{
                    &:hover{
                        background: #E50931;
                        color: #fff;
                        border-color: transparent;
                    }
                    margin-right: 15px;
                }
                &:hover{
                    background: #f3d127;
                }
			}
		}
	}
}

button.confirm.cancel {
	border: 1px solid #f3d127;
	background: #fff;
	color: #000;
	border-radius: 10px;
	width: 150px;
	height: 44px;
	font-size: 14px;
	margin-top: 14px;
	transition: 0.3s all;

	&:hover {
		background: #E50931;
		color: #fff;
		border-color: transparent;
	}
	margin-right: 15px;
}