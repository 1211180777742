#calculator-new {
  .modal-header {
    button {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  .calculator-modal-new,
  .modal-content {
    width: 620px;
    max-width: unset;
    margin: 0 auto;
    padding: 20px 40px 40px;
    .modal-body {
      padding: 0;
    }
    .col-md-6.col-12 {
      width: 100%;
    }
  }
  @media (max-width: 911px) {
    .calculator-modal {
      .modal-content {
        padding: 10px;
      }
      .modal-body {
        margin-top: 10px;
      }
    }
  }
}
