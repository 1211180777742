#parcelsModal {
  .warnings {
    width: 100%;
    margin: 0 auto;
    .content {
      padding: 10px;
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      // display: flex;
      // align-items: flex-start;
      height: 100%;
      &.first-content {
        margin-right: 10px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #121212;
        margin-bottom: 0;
      }
      button {
        border: 1px solid #f3d127;
        border-radius: 10px;
        font-size: 12px;
        margin-top: 8px;
        font-weight: 400;
        padding: 5px 10px;
        white-space: nowrap;
        transition: 0.5s all;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background: #f3d127;
        }
        i {
          margin-right: 5px;
        }
      }
    }
  }
  .row.g-2.parcels-form {
    .col-12.col-md-6:first-child {
      padding-right: 10px;
    }
    .col-12.col-md-6:nth-child(2) {
      padding-left: 10px;
    }
  }
}
.btn-close {
  font-size: 12px;
  opacity: 1;
}
