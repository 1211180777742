#balls-modal{
	.modal-dialog {
		.modal-content{
			padding: 10px;
			position: relative;
			.modal-header{
				display: none;
			}
			.close {
				position: absolute;
				right: 30px;
				top: 30px;
				padding: 0;
			}
		}
	}
	.balls {
		.balls-row {
			width: 100%;
			margin: 0 auto;
			.balls-box {
				background: #ffffff;
				padding: 25px 20px;
				border-radius: 10px;
				h5 {
					font-weight: normal;
					font-size: 18px;
					line-height: 20px;
					color: #000000;
					margin-bottom: 25px;
				}
				.title {
					font-size: 19px;
					margin-bottom: 15px;
					font-weight: 500;
				}
				.number-card {
					display: flex;
					width: 100%;
					margin-bottom: 25px;
					background: #fff;
					border-radius: 10px;
					img {
						margin-left: 10px;
						margin-right: 10px;
					}
				}
				.btn.number-card{
					display: flex;
					align-items: center;
					justify-content: space-between;
					height: 45px;
				}
				.password-input {
					margin-bottom: 25px;
				}
				.total {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 25px;
					p,
					h4 {
						margin: 0;
					}
					h4 {
						font-size: 21px;
						font-weight: 500;
					}
				}
				.send-button {
					background: #fff;
					border: 1px solid #f3d127;
					color: #000;
					border-radius: 10px;
					height: 44px;
					width: 100%;
					transition: 0.5s all;
					&:hover {
						background: #f3d127;
					}
				}
				.bottom-text {
					margin: 20px 0 0 0;
					font-size: 14px;
					line-height: 20px;
					a{
						text-decoration: none;
					}
				}
			}
		}
	}
}
