#account-modal {
	.modal-dialog {
		.modal-content {
			position: relative;
			.modal-header {
				display: none;
			}
			.close,
			.back {
				// position: absolute;
				right: 20px;
				top: 20px;
				padding: 0;
			}
			.back {
				left: 30px;
				width: fit-content;
			}
		}
	}
	.my-accounts {
		.balls-row {
			width: 100%;
			margin: 0 auto;
			.balls-box {
				margin-top: 20px;
				background: #ffffff;
				padding: 25px 20px;
				border-radius: 10px;
				h5 {
					font-weight: normal;
					font-size: 18px;
					line-height: 20px;
					color: #000000;
					margin-bottom: 25px;
				}
				.title {
					font-size: 19px;
					margin-bottom: 15px;
					font-weight: 500;
				}
				.number-card {
					display: flex;
					width: 100%;
					justify-content: space-between;
					align-items: center;
					height: 45px;
					border: 1px solid #e6e6e6;
					transition: 0.5s all;
					cursor: pointer;
					margin-bottom: 25px;
					background: #fff;
					border-radius: 10px;
					img {
						margin-left: 10px;
						margin-right: 10px;
					}
					.circle {
						margin-left: 20px;
						width: 22px;
						height: 22px;
						border: 4px solid #f4f4f4;
						border-radius: 50%;
					}
					.circle span {
						border-radius: 50%;
						width: 100%;
						height: 100%;
						display: block;
						background: #fff;
					}
					// &.active .circle {
					// 	border-color: #f3d127;
					// }
					&.active .circle span {
						background: #f3d127;
					}

					&.active,
					&:hover {
						border-color: #f3d127 !important;
						color: black;
					}
				}
				.total {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 25px;
					p,
					h4 {
						margin: 0;
					}
					h4 {
						font-weight: 500;
						font-size: 21px;
					}
				}
				.send-button {
					background: #fff;
					border: 1px solid #f3d127;
					color: #000;
					border-radius: 10px;
					height: 44px;
					width: 100%;
					transition: 0.5s all;
					&:hover {
						background: #f3d127;
					}
				}
				.bottom-text {
					margin: 20px 0 0 0;
					font-size: 14px;
					line-height: 20px;
					a {
						text-decoration: none;
					}
				}
			}
		}
	}
}
