.tracking-list {
    padding: 0;
        .item-title{
            margin-bottom: 8px;
            font-weight: 500;
        }
        .item-deckaration__value{
            font-size: 16px!important;
        }
	.parcels-list-details {
		width: 100%;
        ul{
            padding-left: 0!important;
        }
	}
}
