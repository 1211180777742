#calculator-new {
  .calculator-section-title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 30px;
    margin-top: 80px;
  }
  .calculator-row {
    width: 100%;
    margin: 0 auto;
    border-radius: 20px;
    overflow: hidden;
    background: #ffffff;
    padding: 20px;

    .calculator-total {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 12px 0 12px;
      border-top: 1px solid #e7e7e7;
      .calc-title,
      .calc-amount {
        margin: 0;
        font-size: 16px;
      }
      .calc-amount {
        font-size: 18px;
      }
    }
    .calculator-box {
      input,
      select {
        font-size: 14px;
        border: none;
        margin-bottom: 30px;
        box-shadow: none;
      }
      select:active,
      select:focus {
        border: 1px solid #f3d127 !important;
      }
      input[type="checkbox"] {
        margin: 0;
        margin-top: 8px;
      }
      .checkbox-row {
        margin-bottom: 22px;
      }
      .extra-data {
        margin: 0 auto;
        width: 100%;
        .col-3:first-child {
          padding-left: 0;
        }
        .col-3:last-child {
          padding-right: 0;
        }
      }

      .calc-interval {
        position: relative;
        margin: 0 auto;
        border: none;
        border-radius: 50%;
        outline: none;
        box-shadow: none;
        padding: 0;
        width: 98%;
        margin-top: -4px;
        &:active {
          border: none !important;
        }
      }

      .interval-input {
        margin-bottom: 0;
      }
      input[type="range"]:focus {
        outline: none;
      }

      .interval-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 2px 0 20px 0;
        .interval {
          .line {
            height: 10px;
            width: 1px;
            background: #000;
            margin-left: 60%;
          }
          &:first-child {
            .line {
              margin: 0 auto;
            }
          }
          span {
            font-size: 12px;
            font-weight: 500;
            color: #b7b7b7;
          }
        }
      }
      label {
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}
