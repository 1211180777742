.help-block {
  font-size: 13px;
  color: red;
  position: absolute;
  bottom: -20px;
  left: 15px;
}
.input-margin-b {
  position: relative;
  margin-bottom: 25px;
}
.uz-tracking #tracking {
  border-top: none;
}

.uz-tracking #tracking .steep-0-bottom {
  top: 130px;
}

@media (max-width: 991px) {
  .uz-tracking #tracking .steep-0-bottom {
    top: 110px;
  }
}
