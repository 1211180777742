@import "../public/scss/balls_modal.scss";
@import "../public/scss/accounts_modal.scss";
@import "../public/scss/balance.scss";
@import "../public/scss/calculator.scss";
@import "../public/scss/calculator_modal.scss";
@import "../public/scss/log_out_modal.scss";
@import "../public/scss/add_address_modal.scss";
@import "../public/scss/parcels-list-declaration_modal.scss";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.thumb-swiper .swiper:last-child {
  display: none;
}

.festival.command {
  .thumb-swiper .swiper:last-child {
    display: block;
  }
}

.swipers,
.video,
.calculator-row,
.help-row,
.service-right > .swiper,
.about-row-swiper > .thumb-swiper > .mySwiper2,
.video-preview {
  overflow: hidden;
  border-radius: 20px;

  // border: 1px solid #f3d127;
  img {
    z-index: 10;
    overflow: hidden;
    // border-radius: 20px;
    border: 1px solid #f3d127;
  }

  .swiper-horizontal {
    img {
      border-radius: unset;
      border: unset;
    }
  }

  .swiper {
    z-index: 11;
  }

  input,
  select,
  textarea {
    border: 1px solid #e6e4e4 !important;

    &:active {
      border: 1px solid #f3d127 !important;
    }
  }

  .main.swiper-content {
    width: 100%;

    .swiper-title {
      display: flex;
      align-items: flex-end;

      .text {
        width: fit-content;
        margin: 0;
        margin-left: auto;
        color: #212529;
      }

      &.first {
        span {
          color: #fff;
        }
      }

      span {
        margin-right: 5px;
        position: relative;
        color: #212529;
      }

      span::before {
        content: "";
        position: absolute;
        background-color: #f3d127;
        width: 0;
        height: 3px;
        top: auto;
        bottom: 0;
      }

      span::after {
        content: "";
        border-bottom: 3px solid #9fa0a0;
        width: 100%;
        display: block;
      }
    }
  }
}

.header-search {
  position: relative;
}

.search-result {
  z-index: 10000;
  position: absolute;
  top: 120%;
  width: 100%;
  background: #fff;
  border: 1px solid #f3d127;
  padding: 10px;
  border-radius: 10px;

  .no-data {
    text-align: center;
  }

  .result-list {
    list-style: none;
    padding: 0;

    .title {
      padding: 10px 10px 5px 10px;
      font-weight: 600;
      margin-top: 10px;
    }

    .result-item {
      .texts {
        display: block;
        width: 100%;
        overflow: hidden;

        p {
          margin-bottom: 5px;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;
        }
      }

      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;

        .shop-image {
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          min-width: 60px;
          margin-right: 10px;
          max-height: 60px;
          max-width: 60px;
          padding: 3px;
          border: 1px solid #f3d127;
          border-radius: 50%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        display: flex;
        width: 100%;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 5px 10px;
        color: #000;
        text-decoration: none;
        transition: 0.3s all;

        &:hover {
          color: #f3d127;
        }
      }
    }
  }
}

.swiper-slide {
  .slider-content {
    height: 100%;
    width: 100%;

    img {
      width: 100%;
    }
  }
}

.how-it-work {
  margin-top: 80px;

  .how-title {
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 30px;
    text-align: center;
  }

  .btn.how-btn {
    position: relative;
    margin: 0 auto;
    left: unset;
  }

  .how-steps {
    counter-reset: section;
    margin-bottom: 30px;
    list-style: none;
    display: flex;
    align-items: flex-start;
    position: relative;
    padding: 0;
    justify-content: space-between;

    .how-step {
      position: relative;
      text-align: center;

      .numberic {
        border-radius: 17px;
        border: 1px solid #f3d127;
        color: #383838;
        font-size: 26px;
        font-weight: 600;
        height: 60px;
        line-height: 60px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
      }

      h3 {
        font-size: 16px;
        margin-top: 20px;
      }

      img {
        position: absolute;
        width: 150px;
        top: 20px;
        height: 22px;
        right: 0;
        transform: translateX(50%);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  @media (max-width: 991px) {
    margin-top: 50px;

    .btn.how-btn {
      padding: 10px;
      height: auto;
    }

    .how-steps {
      margin-bottom: 20px;

      .how-step {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        text-align: left;

        .numberic {
          margin: 0;
          margin-right: 20px;
          width: 50px;
          height: 50px;
          min-width: 50px;
          font-size: 16px;
        }

        h3 {
          margin: 0;
          font-size: 14px;
        }

        img {
          left: 16px;
          right: unset;
          width: 20px;
          top: 107%;
          transform: rotate(90deg) translateY(-50%);
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .how-title {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 30px;
    }

    .how-steps {
      .how-step {
        img {
          left: 8px;
        }
      }
    }
  }
}

.festival.command {
  .about-row {
    .thumb-swiper.video {
      height: 350px;
    }

    .mySwiper2 {
      .swiper-slide {
        align-items: flex-start;
        text-align: start;
        background: #fff;

        .slider-content {
          align-items: center;
          width: 100%;
          display: flex;
          margin-top: 70px;
          margin-left: 80px;
          height: auto;

          .texts {
            margin-left: 20px;

            .title {
              font-weight: 600;
              font-size: 26px;
              line-height: 30px;
              border-bottom: 1px solid #f4f4f4;
            }

            .description {
              font-size: 18px;
              line-height: 20px;
              color: #7e6e1a;
              margin-bottom: 0;
            }
          }

          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: contain;
          }
        }
      }
    }

    .mySwiper {
      .swiper-wrapper {
        .swiper-slide {
          background: #fff;
          border: 1px solid #e6e4e4;

          &.swiper-slide-thumb-active {
            background: #ffffff;
            border: 1px solid #f3d127;
          }

          .slider-content {
            display: flex;
            width: 100%;
            align-items: center;
            padding-left: 12px;

            img {
              width: 40px;
              height: 40px;
              border: 1px solid #f3d127;
              border-radius: 50%;
            }

            .texts {
              margin-left: 8px;

              .title {
                text-align: start;
                font-size: 14px;
                margin-bottom: 2px;
                line-height: 16px;
              }

              .description {
                text-align: left;
                margin-bottom: 0;
                font-size: 12px;
                color: #e8c305;
                line-height: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.play-animation {
  &::before {
    animation: progress-animation 6.2s linear forwards;
  }
}

.swiper-title {
  span.no-done::before {
    width: 0;
  }

  span.done::before {
    width: 100% !important;
  }
}

@keyframes progress-animation {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.contact-us-form,
.how-yellow-operates,
.business-opportunity-boxes .box,
.contacts-form,
.contacts-info-group.col-12,
.balls-box,
.bonus-invite.link {
  z-index: 10;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #f3d127;
}

.form-control.calc-interval {
  border: none !important;
}

.custom-radio {
  appearance: none;
  border: 1px solid #f4f4f4;
  background-color: #fff;
  border-radius: 50%;
  padding: 7px;
}

input[type="radio"]:checked.custom-radio {
  background-color: #f3d127;
  border: 1px solid #f4f4f4;
}

.custom-radio:focus-visible {
  outline-offset: 0;
}

.navbar {
  overflow: unset;

  .nav-item {
    // margin: 0 4px;
    .btn-profile-icon {
      &::before {
        display: none;
      }

      padding-right: 0;
      position: relative;

      .unreads {
        position: absolute;
        top: 0;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f3d127;
        z-index: 2;
        font-size: 10px;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        right: -4px;
      }
    }
  }
}

.fedex {
  .content-right-body-input {
    font-size: 14px;
  }

  table {
    thead {
      tr {
        height: 40px;

        th {
          font-weight: 500;
        }
      }
    }

    tbody {
      tr {
        td {
          font-weight: 400;

          button {
            img {
              transition: 0.3s;
              filter: brightness(1) invert(0.3);
            }

            &:hover {
              img {
                filter: unset;
              }
            }
          }
        }

        td:first-child {
          border-left: none !important;
        }
      }
    }
  }

  .row {
    align-items: flex-end;

    span {
      font-size: 14px;
      font-weight: 400;
    }

    .btn {
      width: 100%;
      background: #f3d127;
      height: 45px;
      text-decoration: none;
      color: #121212;
      display: block;
      text-align: center;
      border-radius: 10px;
      margin-bottom: 16px;
      font-size: 14px;
      transition: 0.3s;

      &.remove {
        margin-left: auto;
      }

      &:hover {
        border-color: #f3d127;
        background: transparent;
      }

      &.filter-btn {
        background: transparent;

        &:hover {
          background: #f3d127;
        }
      }
    }

    span.favourite {
      font-size: 10px;
      color: #f3d127;
      margin-left: 10px;
    }
  }

  &.create {
    .content-right-body-input {
      height: 45px;
    }

    .radio-buttons {
      display: flex;
      align-items: center;
      height: 50px;

      label {
        font-size: 14px;
        font-weight: 500;
        margin-right: 50px;
        padding-left: 10px;
      }
    }

    .custom-radio {
      border-color: #828282;
    }

    .btn {
      min-width: 160px;
    }

    hr {
      margin: 10px 0 26px 0;
    }

    .records {
      hr {
        margin: 20px 0;
      }
    }

    .fedex-tariff {
      thead,
      tbody {
        tr {
          th,
          td {
            text-align: center;
            border: none !important;
          }
        }
      }
    }
  }

  thead {
    tr {
      th {
        background-color: #f4f4f4 !important;
        border-color: #f0f0f0 !important;
      }
    }
  }

  @media (max-width: 991px) {
    &.create {
      .radio-buttons {
        margin: 0;
      }

      .records {
        .col-md-2,
        .col-md-3,
        .col-md-4 {
          padding: 0 4px;
        }

        hr {
          margin-top: 0;
        }
      }
    }
  }
}

.subscription-page {
  .title {
    text-align: center;
    margin: 30px auto 60px;
  }

  table {
    width: 100%;
    overflow: auto;
    table-layout: fixed;
    border-bottom: 0;
    border-right: 0;
    margin-bottom: 200px;
  }

  table tr td {
    padding: 25px;
    vertical-align: top;
    border: 1px solid #f4f4f4;
    position: relative;
  }

  table tr td i {
    font-style: italic;
  }

  table tr td p {
    display: inline;
    font-size: 32px;
    line-height: 30px;
    font-weight: 400;
  }

  table tr td span {
    font-size: 14px;
    line-height: 16px;
    color: #e8c305;
    margin-left: 3px;
  }

  table tr td span.active-subs {
    color: #27ae60;
    white-space: pre-wrap;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
  }

  table tr td h2 {
    display: inline;
    font-size: 44px;
    font-weight: 500;
  }

  table tr td h2 ~ span {
    color: #a9a9a9;
    font-size: 16px;
  }

  table tr td h2 ~ button {
    padding: 13px 25px;
    border: 1px solid #f3d127;
    color: #000;
    line-height: 1;
    text-decoration: none;
    border-radius: 10px;
    width: fit-content;
    margin: 20px auto 0;
    display: block;

    &:hover {
      background: #f3d127;
    }
  }

  table tr td h5,
  table tr td h6 {
    font-size: 20px;
    font-weight: 400;
  }

  table tr td h5 {
    text-align: left;
  }

  @media (max-width: 991px) {
    .title {
      font-size: 20px;
      margin: 16px auto;
    }
    .rates-list,
    .rates-header {
      width: 100%;
    }
    .rates-header,
    .benefits {
      display: flex;
    }
    .rates-header {
      &.fixed {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 4;
        animation-name: fixedHeader;
        animation-duration: 0.4s;
        background-color: #fff;
        box-shadow: 0 3px 6px rgba(51, 51, 51, 0.05);
        padding: 12px;
      }
      .rate-item {
        position: relative;
        flex: 1;
        &:first-child {
          h3 {
            &::after {
              content: "Favourite";
              font-size: 10px;
              margin-left: 4px;
              color: #e8c305;
            }
          }
        }
        &:last-child {
          padding-left: 12px;
          border-left: 1px solid #ccc;
        }
        h3 {
          font-size: 20px;
          color: #0a0a0a;
        }
        h2 {
          font-size: 24px;
          font-weight: 600;
          span {
            font-size: 10px;
            color: #a8a8a8;
          }
        }
        .btn {
          border-radius: 8px;
          font-size: 14px;
        }
        .active-subs {
          position: absolute;
          top: 0;
          right: 0;
          font-weight: 500;
          color: #27ae60;
          font-size: 12px;
          white-space: pre-wrap;
        }
      }
    }

    .rates-list {
      margin-bottom: 40px;
      .label {
        font-size: 14px;
        margin-top: 24px;
        margin-bottom: 0;
      }
      i {
        font-size: 10px;
        font-style: italic;
        display: block;
      }

      .benefits {
        padding-bottom: 4px;
        border-bottom: 1px solid #ccc;
        margin-top: 12px;
        .benefit {
          flex: 1;
          text-align: center;
          &:first-child {
            border-right: 1px solid #ccc;
            margin-right: 12px;
          }
        }
      }
    }
  }
}
@keyframes fixedHeader {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.claims-page {
  button {
    border-radius: 8px;
    min-width: 145px;
    height: 45px;
    transition: 0.3s;

    &.close {
      min-width: auto;
    }

    &:hover {
      background: #f3d127;
    }
  }

  h3 {
    font-weight: 400;
    font-size: 28px;
    line-height: 39.01px;
  }

  .claim-user-info {
    h3 {
      margin-bottom: 12px;
    }

    form {
      .col-md-6 {
        h6 {
          margin-bottom: 16px;
          padding-left: 4px;
        }

        .col-12,
        h6 {
          width: 80%;
        }

        &:nth-child(2) {
          h6 {
            margin-left: auto;
          }
        }

        .fields {
          border-right: 3px solid #f3d127;
        }

        &.last {
          .col-12 {
            margin-left: auto;
          }

          .fields {
            border-right: none;
            border-left: 3px solid #f3d127;
          }
        }
      }

      .input-margin-b {
        .prefix {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 10px;
          width: auto;
          z-index: 5;
          border: none;
          background: #fff;

          & ~ input {
            padding-left: 20px;
          }
        }
      }

      .submit-btn {
        margin: 100px 0 40px auto;
      }

      .products {
        .fields {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }

        .field {
          width: 18%;

          &.name {
            width: 22%;
          }
        }
      }

      span {
        font-size: 14px;
      }

      h6.total {
        width: 68%;
      }

      .pictures-list {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        button {
          min-width: auto;
          padding: 0;
          height: auto;

          i {
            color: #ff0f00;
          }
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80%;
        }
      }

      .pictures {
        display: flex;
        flex-direction: column;

        span {
          flex-shrink: 0;
        }

        label {
          position: relative;
          width: 100%;
          border: 1px solid #e6e4e4;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          cursor: pointer;
          flex-grow: 1;

          i {
            font-size: 40px;
          }

          input {
            position: absolute;
            visibility: hidden;
          }
        }
      }
    }
  }

  .claim-confirm {
    h3 ~ span {
      font-weight: 300;
      font-size: 14px;
      line-height: 19.36px;
      color: #a8a8a8;
    }

    h4.text-center {
      font-size: 20px;
    }

    .row {
      .col-md-6 {
        h5 {
          font-size: 16px;
          font-weight: 600;
        }

        h6 {
          width: 70%;
          padding: 10px 0;
          margin: 20px auto;
          border-bottom: 1px solid #e2e2e2;
          font-size: 14px;
          font-weight: 400;
        }
      }

      .col-md-7,
      .col-md-4 {
        span {
          display: block;
          margin-bottom: 20px;
        }

        &.col-md-7 {
          border-bottom: 1px solid #e2e2d2;

          h6 {
            font-size: 14px;
            font-weight: 400;
          }
        }

        &.col-md-4 {
          text-align: center;

          h6 {
            color: #e8c305;
            font-size: 10px;
          }
        }
      }
    }

    .product-info {
      width: 100%;

      div {
        text-align: center;
        position: relative;

        .line {
          position: absolute;
          border-bottom: 1px solid #e2e2d2;
          width: calc(100% - 20px);
          bottom: 0;
        }

        span {
          word-wrap: break-word;
        }

        * {
          font-weight: 400;
        }

        h6 {
          font-size: 16px;
        }

        span {
          display: block;
          padding: 10px 0;
          margin: 20px 8px 0;
          font-size: 14px;
          font-weight: 400;
        }

        &.id {
          span,
          h6 {
            margin-left: 0;
            margin-right: 8px;
          }
        }

        &.price {
          span {
            margin-right: 0;
          }
        }
      }
    }

    .submit-btn {
      display: block;
      margin: 80px 0 80px auto;
    }
  }

  .confirmed {
    .btn.p-0 {
      min-width: unset;
      margin-left: 10px;
    }

    .claim-info {
      width: 35%;
      margin: 100px auto;
      text-align: center;

      h2 {
        margin: 0 auto;
        font-weight: 300;
        padding: 0 40px 4px;
        border-bottom: 1px solid #e2e2e2;
      }

      button {
        margin: 40px auto;
      }

      a,
      span {
        font-weight: 300;
      }

      a {
        color: #000000;
        text-decoration: none;

        span {
          color: #0038ff;
        }
      }
    }
  }

  .claim-status {
    table {
      thead {
        vertical-align: middle;

        tr {
          height: 40px;
          background: #f4f4f4;
        }
      }

      th,
      td {
        border-width: 1px;
        background-color: unset !important;
        border-color: #f0f0f0 !important;
        text-align: center;
        font-size: 14px;

        &:first-child {
          border-left: none;
        }

        &.last {
          border-right: none;

          &.rejected {
            color: #dd0404;
          }

          &.pending {
            color: #e8c305;
          }

          &.approved {
            color: #27ae60;
          }
        }
      }
    }

    .review {
      width: fit-content;

      button {
        min-width: fit-content;
      }

      h5 {
        font-size: 16px;
      }

      .commit {
        button {
          display: block;
          margin-left: auto;
          margin-top: 20px;
        }

        textarea {
          width: 411px;
          min-height: 114px;
        }
      }
    }
  }
}

.wallet-subscription {
  .status,
  .info {
    justify-content: space-between;
    max-width: 250px;
    padding: 15px 25px;

    h5:first-child {
      font-weight: normal;
    }
  }

  .info {
    max-width: 660px;
    justify-content: space-between;
    padding-right: 0;

    h5 {
      font-weight: normal;

      img {
        &:first-child {
          margin-right: 10px;
        }
      }

      span {
        font-size: 16px;
        color: #e2e2e2;
        margin-right: 50px;
      }

      button {
        margin-left: 20px;
        padding: 0;
      }
    }
  }

  .bb {
    border-bottom: 1px solid #e2e2e2;
  }

  .update {
    height: 45px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    background-color: #fff;
    border: 1px solid #f3d127;
    border-radius: 8px;
    box-sizing: border-box;
    transition: all 0.3s;
    padding: 0 25px;

    &:hover {
      background-color: #f3d127;
    }
  }

  h6 {
    font-weight: normal;
    font-size: 15px;
  }

  @media (max-width: 991px) {
    .status,
    .info {
      justify-content: space-between;
      max-width: 100%;
      padding: 15px 0;

      h5 {
        font-size: 16px;

        &:first-child {
          min-width: fit-content;
        }
      }
    }

    .info {
      h5 {
        span {
          font-size: 12px;
          color: #e2e2e2;
          margin-right: 0;
          display: block;
          text-align: right;
        }
      }

      &:last-child {
        overflow: hidden;

        h5:last-child {
          overflow-wrap: break-word;
          max-width: 50%;
        }
      }
    }
  }
}

.fedex-info {
  h5 {
    font-size: 24px;
  }

  & > h5 {
    text-align: right;

    span {
      margin-right: 100px;
      font-size: 16px;
      font-weight: normal;
    }
  }

  .top-info {
    background: #f4f4f4;
    border-radius: 10px;

    span {
      font-size: 14px;
      color: #a8a8a8;
    }

    h6 {
      font-size: 16px;
      color: #000;
      margin: 2px 0 20px;
      font-weight: normal;
    }

    .col-md-7,
    .col-md-5 {
      padding: 10px 20px;
    }

    .col-md-7 {
      border-left: 1px solid #e2e2e2;
    }
  }

  .middle-info {
    img {
      margin-right: 20px;
    }

    div {
      span {
        color: #a8a8a8;
        font-size: 14px;
        line-height: 1;
      }
    }
  }

  .socials {
    justify-content: center;

    a {
      text-decoration: none;
      margin: 0 10px;

      i {
        font-size: 30px;
        transition: 0.3s;
        color: #e8c305;
      }

      &:hover {
        i {
          color: #121212;
        }
      }
    }
  }
}

.shop-page-bread-crumb {
  .container {
    display: flex;
    justify-content: space-between;

    .breadcrumbs {
      flex: 1;

      .breadcrumb {
        margin-bottom: 0;
      }
    }

    .navbar {
      flex: 3;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: nowrap;
      padding: 0;

      .nav-link {
        margin-left: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #fff;
        border-radius: 10px;
        height: 38px;
        min-width: 39px;
        width: 39px;

        i {
          transition: 0.3s;
          color: #000;

          &:hover {
            color: #f3d127;
          }
        }
      }

      // .header-search {
      //   width: 40%;
      //   &.active {
      //     width: 100%;
      //   }
      //   .header-search-wrapper {
      //     input {
      //       display: block;
      //     }
      //     .search-button {
      //       i {
      //         transition: 0.3s;
      //       }
      //       &:hover {
      //         background: #fff;
      //         i {
      //           color: #f3d127;
      //         }
      //       }
      //     }
      //   }
      // }
    }
  }
}

.lang-menu {
  background: #f4f4f4;
  border-radius: 4px;
  width: 60px;

  .selected-lang {
    padding: 0;

    i {
      &::before {
        max-width: 10px;
      }
    }
  }

  &.act {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    ul {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }

  ul {
    width: 60px;
  }
}

.payment-invoice {
  background: #f6f6f6;
  padding: 55px 200px;
  min-height: 100vh;
  .row {
    align-items: flex-start;
    .left-side {
      text-align: center;

      border-radius: 24px;
      padding: 37px 73px 23px;
      p {
        margin-bottom: 18px;
        font-size: 14px;
        line-height: 14px;
        color: #000000c3;
        font-weight: 600;
      }
      h4 {
        font-size: 34px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 36px;
      }
      button {
        padding: 20px 61px;
        background: #f8d52e;
        border-radius: 15px;
        font-size: 22px;
        color: #0a0a0a;
        line-height: 1;
        font-weight: 500;
      }
      hr {
        margin: 44px auto 36px;
      }
      span {
        font-size: 12px;
        line-height: 14px;
        color: #000000;
      }
    }

    .left-side,
    .order-details,
    .merchant-details {
      background: #ffffff;
    }

    .order-details,
    .merchant-details {
      padding: 20px 22px;
      border-radius: 18px;
    }

    .order-details {
      .logo {
        width: 124px;
        margin-left: -8px;
      }

      p,
      span {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }

      & > p {
        margin: 18px 0;
      }

      & > div {
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;
        margin-bottom: 10px;
        p {
          color: #000000c3;
          white-space: nowrap;
        }
        span {
          text-align: right;
          color: #646464;
          white-space: pre-wrap;
          line-break: anywhere;
        }
      }
      .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 26px 0 0;
        & > button {
          padding: 5px 20px;
          font-size: 10px;
          font-weight: 600;
          line-height: 14px;
          color: #515151;
          border: 2px solid #515151;
          text-align: center;
        }
        div {
          display: flex;
          gap: 15px;
          button {
            padding: 0;
            img {
              width: 20px;
            }
          }
        }
      }
    }

    .merchant-details {
      margin: 18px 0 10px;
      p,
      a {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: #000000c3;
      }
      a {
        font-weight: 500;
        color: #646464;
        text-decoration: none;
        line-break: anywhere;
      }
      button {
        padding: 0;
        display: block;
        margin: 0 auto;
        line-height: 1;
        i {
          color: #646464;
        }
      }
      div {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        &.middle {
          margin: 10px 0;
        }
        a {
          color: #64aaf6;
        }
        img {
          width: 22px;
        }
      }
    }

    .right-side {
      & > img {
        width: 89px;
      }
      .bottom-text {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        p {
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 791px) {
    padding: 24px 16px;
    .row {
      gap: 18px;
      .left-side {
        padding: 37px 43px 23px;
      }
    }
  }
}

.join-us-form,
.contacts-form,
.profile-info-group {
  .bt.btt {
    font-size: 14px;
  }
}

.join-us-form .bt.btt {
  z-index: 3;
}

.join-us-form .countries {
  z-index: 5;
}

.join-us-form .amounts {
  z-index: 4;
}

#faq-content {
  .faq-content {
    .accordion {
      .accordion-body {
        & > div {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          width: 100%;
          margin-top: 16px;

          p {
            width: 50%;
            margin: 0;
          }

          a.vide {
            cursor: pointer;
            display: block;
            text-align: center;
            width: 46%;
            height: 350px;
            border-radius: 20px;
            overflow: hidden;
            border: 1px solid #e8c305;

            iframe {
              object-fit: cover;
            }

            * {
              background-color: #fff !important;
              background: #fff !important;
            }
          }
        }
      }
    }
  }
}

.profile-info-group .dropdown-menu,
.join-us-form .dropdown-menu,
.contacts-form .dropdown-menu,
.bonus .dropdown-menu {
  &.show {
    overflow: hidden;
    transform: translate3d(0px, 38px, 0px);
  }

  .dropdown-item {
    padding: 6px 10px;
    border: none;
    border-radius: unset;

    &:hover {
      background-color: #e9ecef;
    }
  }
}

#partnerSwiper {
  .swiper .swiper-wrapper {
    transition-timing-function: linear;
  }

  .slide-buttons {
    display: none;
  }
}

@media (min-width: 1250px) {
  .container {
    min-width: 1150px;
  }
}

@media (max-width: 1250px) {
  .swiper {
    .main.swiper-content {
      .swiper-content-texts {
        .text {
          font-size: 22px;
        }
      }
    }
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1400px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1600px;
  }
}

@media (min-width: 2160px) {
  .container {
    max-width: 1800px;
  }
}

@media (min-width: 2880px) {
  .container {
    max-width: 2440px;
  }
}

.swiper .swiper-wrapper .swiper-slide .swiper-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

#loginContent {
  .login-content {
    #tracking {
      & > .container {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
    }
  }
}

#service-modal {
  .offers {
    .modal-content {
      padding: 20px;
    }

    margin-bottom: 0;
    width: fit-content;

    .modal-header {
      padding: 0;
      margin-bottom: 20px;

      .modal-title {
        line-height: 1;
        font-size: 22px;
      }
    }

    .modal-list {
      margin: 0;

      .form-check-input {
        margin-right: 10px;
      }
    }
  }
}

#view-invoice-modal {
  .modal-dialog {
    width: 700px;
    .modal-content {
      padding: 0;
      overflow: hidden;
      .modal-header {
        margin-top: 10px;
        margin-bottom: -20px;
        z-index: 100;
      }
    }
  }
}
.invoice-content {
  padding: 20px;
}

@media (max-width: 991px) {
  #view-invoice-modal .modal-dialog {
    width: 100%;
    .modal-content {
      margin: 0 10px;
      .modal-header {
        margin-top: 0;
      }
      .invoice-content {
        padding: 0px 10px;
        padding-top: 40px;
      }
    }
  }
  .invoice-content {
    .logo2 {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 10px;
    }
    .address {
      padding: 10px 12px !important;
      div {
        div {
          width: 49% !important;
        }
      }
    }
    .table-responsive {
      table {
        tr {
          th,
          td {
            padding: 0 5px;
          }
        }
      }
    }
  }
  .mobile-download {
    width: 700px;
    margin: 0 auto;
  }
}

.re.send-button {
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    cursor: not-allowed;
  }

  i {
    margin-left: 4px;
  }
}

#contacsUs {
  .user-data {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    input {
      margin-right: 10px;
      background: #121212;
      color: #fff;
      border-radius: 10px;
    }

    button {
      height: 45px;
      color: #fff;
      border-radius: 10px;
      min-width: 100px;
      background: #27ae60;
    }
  }

  @media (max-width: 991px) {
    .user-data {
      justify-content: flex-end;

      input,
      button {
        height: 35px;
        padding: 0 10px;
        font-size: 12px !important;
      }

      input {
        max-width: 150px;
        margin-right: 0;
        margin-bottom: 4px;
      }
    }
  }
}

.only-mobile {
  display: none;
}

#registerContent,
#loginContent {
  .content-right {
    .content-body {
      input[type="password"].password-input {
        font-size: 10px;
      }

      .btns {
        display: flex;
        align-items: center;

        a,
        input,
        button {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      input[type="checkbox"] {
        margin-top: 5px;
      }
    }
  }
}

.title-top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .line {
    border-bottom: 1px solid #c4c4c4;
    width: 50%;
  }

  .about-title {
    position: relative;
    display: inline-block;
    width: fit-content;
    margin-bottom: 0;
    white-space: nowrap;
    margin-left: 30px;
    margin-right: 30px;
  }
}

.offers .modal-bottom .add-btn:hover,
.offers-item .modal-bottom .add-btn:hover {
  background: #f3d127;
}

#parcelsListViewModal {
  .parcels-list-form {
    padding: 20px;
    background: #ffffff;
    border-radius: 10px;
    border: 1px solid #f3d127;

    input {
      border-color: transparent;
      background-color: #fff;
    }
  }
}

.social-btn {
  img {
    animation-name: smsAnimation;
    animation-delay: 7s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    margin-right: 0 !important;
  }
}

.custom-tooltipe {
  position: relative;
  display: inline-block;
  margin-right: 5px;

  .tooltiptext {
    visibility: hidden;
    width: auto;
    top: -30px;
    background: #121212;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    padding: 10px;
    font-size: 12px;
    position: absolute;
    z-index: 1;
  }

  &:hover {
    .tooltiptext {
      visibility: visible;
    }
  }
}

/* Tooltip text */
.btn.have.camera-btn {
  background: rgba(#27ae60, 0.1);

  i {
    color: #27ae60;
  }
}

.sms.box {
  // background: #fff;
  // bottom: 60px;
  // right: 120px;
  // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  // flex-shrink: 0;
  // border: 1px solid rgb(226, 226, 226);
  // position: absolute;
  z-index: 5;
  border-radius: 10px;
  cursor: pointer;
  max-height: 55px;
  text-decoration: none;
  margin-right: 0;
  width: 0;
  transition: width 0.3s;
  display: inline-block;
  color: rgb(0, 0, 0);
  opacity: 0;
  animation-name: smsAnimation;
  animation-delay: 7s;
  animation-duration: 3s;
  animation-fill-mode: forwards;

  ////
  white-space: nowrap;
  align-items: center;
  transition: width 0.5s ease-in-out;
  border-top-left-radius: 64px;
  border-bottom-left-radius: 64px;

  .box-arrow-border {
    position: absolute;
    top: 40%;
    transform: rotate(90deg) translateY(-50%);
    right: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #646464;
    width: 0;
    height: 0;
  }

  .box-arrow-background {
    position: absolute;
    left: -10px;
    top: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    width: 0;
    height: 0;
  }

  .box-content {
    border-radius: 0.25rem;
    overflow: hidden;
    padding: 7px 10px;
    font-size: 14px;
    text-align: center;
  }
}

.no-markets {
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  .nodata-content {
    width: 500px;
    align-items: center;

    .empty-image {
      img {
        margin: 0 auto;
        width: 240px;
      }
    }

    p {
      color: #828282;
      line-height: 19px;
    }

    * {
      text-align: center;
    }
  }

  .market-form {
    width: 350px;
    margin-top: 80px;

    input {
      border-color: #e6e4e4;
      border-radius: 10px;
    }

    .login-btn.btn {
      border-color: #f3d127;
      background: #fff;
      border-radius: 10px;
      height: 45px;
      width: 180px;
      transition: 0.3s all;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;

      &:hover {
        background: #f3d127;
      }
    }
  }

  @media (max-width: 991px) {
    & {
      .nodata-content {
        width: 100%;

        .empty-image {
          width: 50%;
          margin: 0 auto;

          img {
            width: 100%;
          }
        }

        .title {
          font-size: 16px;
        }

        .description {
          font-size: 14px;
        }
      }

      .market-form {
        width: 100%;
        margin-top: 20px;

        .row {
          width: 100%;
          margin: 0 auto;

          .col-12.input-margin-b {
            input {
              border-radius: 10px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

@keyframes smsAnimation {
  from {
    opacity: 0;
    width: 0;
    transition: width 0.5s ease-in-out;
  }

  to {
    opacity: 1;
    width: 100%;
    transition: width 0.5s ease-in-out;
    margin-right: 10px;
  }
}

@keyframes smsAnimation2 {
  from {
    opacity: 1;
    width: 100%;
    transition: width 0.5s ease-in-out;
  }

  to {
    opacity: 0;
    width: 0;
    transition: width 0.5s ease-in-out;
  }
}

.lang-menu {
  .selected-lang {
    i {
      display: table;
      transition: 0.3s all;
    }
  }

  &.act {
    .selected-lang {
      i {
        transform: rotate(180deg);
      }
    }
  }
}

.input-btn {
  margin-top: 0;
}

#message-section {
  .message-content {
    width: 60%;
    margin: 50px auto;
    border: 1px solid #f3d127;
    border-radius: 10px;
    overflow: hidden;

    .message-top,
    .message-footer {
      padding: 5px 10px;

      &.message-top {
        background: #f3d127;

        .contact-button {
          display: flex;
          align-items: center;
          font-weight: 600;
          margin-left: auto;
        }

        h5 {
          margin: 0;
        }

        img {
          filter: brightness(0);
        }
      }
    }

    .message-top,
    .operator-image {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .operator-image {
        width: 40px;
        height: 40px;
        justify-content: center;
        padding: 5px;
        border: 1px solid #f3d127;
        border-radius: 50%;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .message-body {
      .message-scroll {
        padding: 5px 30px;
        display: flex;
        flex-direction: column-reverse;
      }

      .message-wrapper {
        padding: 0 10px;
      }

      .message {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: fit-content;
        border-radius: 10px;
        padding: 6px 0.5rem 0.375rem 0.625rem;
        margin-bottom: 4px;
        position: relative;
        max-width: 480px;
        white-space: pre-wrap;
        word-break: break-word;
        font-size: 14px;
        color: #000;

        span,
        i {
          z-index: 1;
        }

        .btn.delete {
          width: fit-content;
          height: 100%;
          opacity: 0;
          border-radius: 10px;
          z-index: 2;
          left: auto;
          right: 0;
          top: 0;
          bottom: 0;
          transition: 0.3s;
          background: #f3d127;
          position: absolute;

          i {
            color: #000;
          }
        }

        &:hover {
          .btn.delete {
            opacity: 1;
          }
        }

        &.read {
          .btn.delete {
            display: none;
          }
        }

        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          height: 25px;
        }

        &.CUSTOMER {
          align-self: flex-end;
          margin-left: auto;
          background: #f3d127;

          &:before {
            right: -7px;
            width: 20px;
            background-color: #f3d127;
            border-bottom-left-radius: 16px 14px;
          }

          &:after {
            right: -26px;
            width: 26px;
            background-color: white;
            border-bottom-left-radius: 10px;
          }
        }

        &.ADMINISTRATION {
          background: #e5e5ea;
          color: black;
          align-self: flex-start;
          margin-right: auto;

          &:before {
            left: -7px;
            width: 20px;
            background-color: #e5e5ea;
            border-bottom-right-radius: 16px;
          }

          &:after {
            left: -26px;
            width: 26px;
            background-color: white;
            border-bottom-right-radius: 10px;
          }
        }

        .time {
          margin-top: auto;
          display: flex;
          align-items: flex-end;
          width: fit-content;
          white-space: nowrap;
          font-size: 10px;
          margin-left: 6px;

          i {
            line-height: 1;
            margin-left: 3px;
            font-size: 14px;
          }
        }
      }
    }
  }

  .message-footer {
    border-top: 1px solid #f3d127;
    position: relative;
    height: 50px;

    .send-btn {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      width: 25px;
      height: 25px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        width: 100%;
      }

      &:disabled {
        img {
          filter: grayscale(1);
        }
      }
    }

    input {
      height: 100%;
      font-size: 16px;
      padding: 0;

      &,
      &:active,
      &:focus {
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.form-control {
  border-color: #e6e4e4;
}

.products-info {
  .btn.modal-outline-btn {
    margin-top: 5px;
    margin-right: 20px;
  }

  .warning,
  .checked,
  .danger {
    display: flex;
    align-items: flex-start;
    // width: 20%;
    margin-top: 5px;

    span {
      margin-left: 10px;
      font-size: 12px;
    }

    img {
      width: 34px;
      height: 34px;
      min-width: 34px;
      border-radius: 4px;
      border: 1px solid #f3d127;
    }

    &.warning {
      color: #f2994a;

      img {
        border-color: #f2994a;
      }
    }

    &.checked {
      color: #27ae60;

      img {
        border-color: #27ae60;
      }
    }

    &.danger {
      color: #e50931;

      img {
        border-color: #e50931;
      }
    }
  }
}

#footer {
  .footer {
    .footer-row {
      .application-links {
        display: flex;
        align-items: center;

        .application-link {
          width: 128px;
          height: 35px;
          overflow: hidden;

          &:first-child {
            width: 123px;
            margin-right: 20px;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  .modal-body {
    color: #000;
  }
}

#service {
  .service-row {
    .service-left {
      .swiper {
        height: 100%;

        .swiper-wrapper {
          .swiper-slide {
            a {
              border: 1px solid #f3d127;
              height: 50px;
              min-width: 180px;
              border-radius: 10px;
              background: #fff;
              display: flex;
              position: absolute;
              text-decoration: none;
              color: #000;
              bottom: 0;
              width: auto;
              padding: 0 10px;
              transition: 0.3s all;
              align-items: center;
              justify-content: center;

              .ri-arrow-right-line {
                margin-top: 3px;
                margin-left: 10px;
              }

              &:hover {
                background: #f3d127;
              }
            }
          }
        }
      }
    }
  }
}

.header-search {
  form {
    .header-search-wrapper {
      position: relative;
      overflow: hidden;

      .search-button {
        background: #fff;
        display: block;
        height: 100%;
        padding: 0 20px;
        position: absolute;
        border: 1px solid #f3d127;
        top: 50%;
        right: 0;
        border-radius: unset;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        transition: 0.3s all;
        transform: translateY(-50%);

        &:hover {
          background: #f3d127;
        }
      }

      input[type="search"]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }

      input[type="search"]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration {
        display: none;
      }
    }
  }
}

.shop-page {
  .shop-page-mobile-search {
    display: none;
  }
}

@media (max-width: 991px) {
  .join-us-content {
    .left-side {
      .thanks {
        text-align: center;

        h3 {
          font-size: 20px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }

  .only-desktop {
    display: none;
  }

  #message-section {
    .message-content {
      // margin-top: 30px;
      width: 100%;

      .message-body {
        .message-scroll {
          padding: 5px 20px;
        }
      }
    }
  }

  #counter {
    .countdown-title {
      font-size: 20px;
      width: 70%;
      text-align: center;
      margin: 20px auto;
    }

    .countdown-text {
      font-size: 14px;
      text-align: center;
    }

    .countdown {
      span {
        font-size: 26px;
        line-height: 33px;
      }

      .word {
        font-size: 14px;
      }
    }

    .countdown-btn-send {
      border: 1px solid #f3d127;
      height: 40px;
      font-size: 14px;
      min-width: 180px;
      border-radius: 10px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }

  #tracking {
    margin-top: 40px;

    .track-search-help {
      padding: 0;

      button {
        text-align: left;
      }

      a {
        white-space: nowrap;
      }
    }
  }

  .parcels-order.show .modal-dialog {
    top: 0;
    transform: unset;
    left: unset;
    max-height: unset;
  }

  #navbar {
    .navbar {
      position: relative;
      .navbar-brand {
        height: 100%;
        position: absolute;
        left: 53%;
        transform: translateX(-50%);

        img {
          height: 100%;
        }
      }
    }
  }

  .sms.box {
    bottom: 90px;
    right: 80px;
    font-size: 12px;

    .box-content {
      padding: 4px 8px;
      font-size: 12px;
    }
  }

  .shop-page-bread-crumb {
    .container {
      padding: 0;

      .shop-page-desktop-search {
        display: none;
      }
    }
  }

  .shop-page {
    .shop-page-mobile-search {
      flex-direction: row;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
      flex-wrap: nowrap;
      padding: 0;

      input[type="search"] {
        border-radius: 10px;
      }

      .btn.search-button {
        i {
          font-size: 16px !important;
        }
      }

      .nav-link {
        margin-left: 10px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 16px !important;
          color: #000;
        }
      }
    }

    .content-row {
      .sidebar {
        border: 1px solid #f3d127;
        border-radius: 10px;
        margin-top: 10px;

        .sidebar-list {
          padding: 10px;
          height: 45px;
          overflow: hidden;

          &.active {
            height: auto;
          }

          &.filter {
            display: none;
          }

          .filter-title {
            display: none;
          }

          li {
            a {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.active,
              &:hover {
                color: #000000;
              }

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  #intro .intro-row {
    background-size: 5px 100% !important;

    &::after,
    &::before {
      top: 50px !important;
      font-size: 30px !important;
    }
  }

  .swipers {
    height: 230px;

    .swiper-wrapper {
      .swiper-slide {
        .swiper-content {
          padding: 14px;
          width: 100%;

          .swiper-button {
            margin: 0;
            height: auto;
            padding: 8px;
            font-size: 12px;
            width: 120px;
          }

          .swiper-content-texts {
            padding: 0;
            text-align: left;

            .swiper-title,
            .text {
              font-size: 12px;
              line-height: 20px;
            }

            .swiper-progress-bar {
              display: none;
            }
          }

          .swiper-text {
            width: 40%;
            text-align: left;
            padding: 0;
            font-size: 10px;
          }

          .swiper-title {
            margin: 0;
            font-size: 20px;
          }
        }
      }
    }
  }

  #how {
    .how {
      .how-row {
        .col-lg-5 {
          margin-bottom: 16px;
        }

        .btn.how-btn {
          position: unset;
          padding: 10px;
          height: auto;
          margin: 0 auto;
          font-size: 14px;

          .ri-arrow-right-line {
            margin-left: 8px !important;
          }
        }
      }
    }
  }

  #chance {
    .chance {
      // margin-top: 30px;
      .chance-section-title {
        margin-bottom: 16px;
      }

      .chance-row {
        .chance-item {
          height: auto;
          padding: 23px 10px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .chance-title {
            padding: 0;
            margin-left: 14px;
          }
        }
      }
    }
  }

  #about {
    .about {
      margin-top: 20px;

      .about-row {
        flex-direction: column-reverse;

        .col-lg-7 {
          padding: 0;
        }

        .col {
          margin: 16px 0;
          text-align: center;

          .about-title {
            margin-bottom: 16px;
            font-size: 22px;
            line-height: 24px;
          }

          .about-text {
            font-size: 14px;
            line-height: 17px;
          }

          .btn.about-btn {
            position: unset;
            padding: 10px;
            height: auto;
            font-size: 14px;
            margin: 0 auto;

            .ri-arrow-right-line {
              margin-left: 8px !important;
            }
          }
        }
      }
    }
  }

  #statistics {
    .statistics {
      margin-top: 50px;

      .statistics-section-title {
        margin-bottom: 16px;
        line-height: 24px;
      }

      .statistics-row {
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: unset;
        justify-content: space-between;

        .statistics-col {
          width: 48.5%;
          height: 180px;

          .statistics-icon {
            width: 43px;
            height: 43px;
            padding: 9px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
            }
          }

          .statistics-count {
            font-size: 20px;
            margin-bottom: 4px;
          }
        }
      }
    }
  }

  #environment {
    .environment {
      .col-md-7 {
        padding: 0;
      }

      .col {
        margin: 16px 0;
      }

      .environment-section-title,
      .environment-title {
        margin-bottom: 16px;
      }

      .environment-btn.btn {
        position: unset;
        padding: 10px;
        height: auto;
        font-size: 14px;
        margin: 0 auto;

        .ri-arrow-right-line {
          margin-left: 8px !important;
        }
      }
    }
  }

  #calculator-new {
    .calculator-section-title {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 24px;
    }

    .calculator-row {
      padding: 14px;

      .calculator-box {
        .checkbox-row {
          margin: 0 auto;
          margin-bottom: 16px;

          label {
            display: none;

            &.form-check-label {
              display: inline;
              margin-left: 6px;
            }
          }

          .col-12 {
            line-height: 1;
          }
        }

        select,
        input[type="number"] {
          margin-bottom: 16px;
          height: 42px;
        }

        .calc-interval {
          margin-top: -20px;
        }
      }
    }
  }

  #help {
    .container {
      padding: 0;
    }

    .help {
      margin-top: 50px;
      background: #fff;

      .help-row {
        border: none;

        .col-md-7 {
          padding: 0;
          margin-bottom: 16px;

          .help-right-col {
            padding: 0;

            .help-title {
              font-size: 20px;
              margin-bottom: 16px;
            }
          }
        }

        .col-md-5 {
          .help-left-col {
            padding: 0;

            .help-text {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
              margin-bottom: 0;
            }

            .help-btn.btn {
              position: unset;

              padding: 10px;
              height: auto;
              font-size: 14px;
              margin: 0 auto;
              margin-top: 16px;
              margin-bottom: 16px;

              .ri-arrow-right-line {
                margin-left: 8px !important;
              }
            }
          }
        }
      }
    }
  }

  #partnerSwiper {
    .partnerSwiper {
      height: 200px;

      .swiper {
        .swiper-slide {
          .logo {
            height: 100%;
            padding: 10px;

            a {
              height: 100%;
            }

            img {
              object-fit: contain;
              height: 100%;
            }
          }
        }
      }
    }

    .partnerSwiper-title {
      font-size: 20px;
      margin: 0 auto;
      margin-bottom: 16px;
    }

    .swiper {
      height: 100%;
    }

    .partnerSwiper-next-btn {
      display: none;
    }
  }

  #commentSwiper {
    margin: 50px auto;

    .partnerSwiper-title {
      font-size: 20px;
      width: 100%;
      line-height: 1;
      text-align: center;
      margin-bottom: 16px;
    }

    .swiper {
      padding: 0 !important;
    }

    .commentSwiper-next-btn,
    .swiper-pagination-bullet {
      display: none;
    }

    .commentSwiper {
      height: 150px;

      .swiper,
      .comment-item {
        height: 100%;
        text-align: left;
        justify-content: space-between;

        .comment-text {
          min-height: unset;
          font-size: 10px;
          margin-bottom: 6px;
        }

        .comment-user-info {
          img {
            width: 24px;
            height: 24px;
          }

          div {
            line-height: 1;
            padding: 0;
          }

          .comment-user-name {
            font-size: 10px;
            margin-bottom: 0;
            line-height: 12px;
          }

          .comment-created-at {
            font-size: 8px;
          }
        }
      }

      .comment-item {
        padding: 10px;
        border-radius: 10px;
      }
    }
  }

  #contacsUs {
    .contact-us {
      margin-top: 30px;
      align-items: center;
      padding: 6px 12px;

      .contact-title {
        font-size: 10px;
      }

      .btn.contact-button {
        align-items: center;
        display: flex;
        font-size: 10px;
        padding: 0px 10px;

        i {
          top: 0;
          margin-right: 6px !important;
        }
      }
    }
  }

  #footer {
    .footer {
      padding: 38px 24px;

      .footer-row {
        flex-wrap: wrap;

        .col-md-3.col-12 {
          text-align: center;

          .footer-logo {
            margin-bottom: 20px;
            width: 80px;
          }

          .application-links {
            justify-content: center;
          }

          p {
            width: 70%;
            margin: 0 auto;
          }

          .footer-title {
            margin: 18px auto;
          }

          a {
            i {
              font-size: 20px;
            }
          }
        }

        .footer-socials {
          ul {
            margin-bottom: 0;
          }
        }

        .col-md-2 {
          li {
            margin-bottom: 6px;
          }
        }

        .footer-title {
          font-size: 16px;
          margin-bottom: 18px;
        }
      }
    }
  }

  #breadcrumbs {
    .breadcrumbs {
      padding: 0;

      .breadcrumb-title {
        font-size: 20px;
      }

      .breadcrumb-item {
        font-size: 14px;
        line-height: 1;

        &::before {
          transform: scale(0.8);
          line-height: 1;
          margin-top: -5px;
        }
      }
    }
  }

  #plan {
    .plan {
      padding: 0 12px;

      .plan-row {
        margin: 0 auto;

        .col-12 {
          padding: 0;
          margin-top: 30px;
        }

        .card {
          padding: 14px;

          .card-head {
            margin-bottom: 16px;

            span {
              width: 35px;
              height: 35px;

              i {
                font-size: 20px;
              }
            }

            h3 {
              font-size: 18px;
            }
          }

          .card-list {
            width: 100%;
            max-width: unset;

            .card-item {
              font-size: 14px;
              margin-bottom: 10px;
            }
          }

          .card-link {
            font-size: 14px;
            margin-bottom: 16px;

            i {
              font-size: 14px;
            }
          }

          .card-btn {
            height: auto;
            font-size: 14px;
            padding: 8px;
          }

          .card-price {
            font-size: 18px;
            margin-bottom: 10px;
            line-height: 1;
          }
        }
      }
    }
  }

  #service {
    .service {
      margin-top: 0;
      padding: 30px 12px 0 12px;
      margin-bottom: 20px;

      .service-row {
        display: flex;
        margin: 0 auto;
        flex-direction: column-reverse;

        .service-right {
          padding: 0;
          margin-bottom: 10px;
          position: relative;

          .service-slider {
            height: 230px;

            .swiper-slide {
              height: 100%;

              img {
                height: 100%;
              }
            }
          }

          .service-btns {
            height: auto;
            bottom: 15px;

            .swiper {
              height: auto;

              .swiper-slide {
                margin-right: 0;
                height: auto;
              }
            }

            button {
              font-size: 12px;
              width: 35px;
              height: 35px;
              padding: 5px;
            }
          }
        }

        .service-left {
          .swiper {
            height: auto;

            .service-text {
              margin-bottom: 0;
            }

            .swiper-wrapper .swiper-slide a {
              position: unset;
              padding: 10px;
              height: auto;
              margin: 0 auto;
              font-size: 14px;
              width: fit-content;
            }
          }
        }
      }
    }
  }

  .contact-us-header {
    margin-top: 30px;

    .contact-us-nav {
      width: 100%;
      justify-content: space-between;

      .us-nav-item {
        margin: 0;
        height: auto;
        padding: 8px;
        font-size: 12px;
        width: 120px;
      }
    }
  }

  #how-work {
    .about-us {
      margin-top: 0;

      .about-row,
      .about-row-swiper {
        margin-bottom: 0;

        .about-title {
          margin: 0 10px;
          margin-top: 0;
        }

        .about-text {
          width: 100%;
        }

        .title-text {
          margin: 0;

          div {
            text-align: center;

            .video-title {
              font-size: 20px;
              margin-bottom: 16px;
            }

            .about-text {
              font-size: 14px;
              margin-bottom: 0;
            }
          }
        }

        .video-content {
          flex-direction: column;

          .video {
            width: 100%;
            margin-bottom: 16px;
            min-width: unset;
          }
        }
      }

      .about-row-swiper {
        flex-direction: column-reverse;
        display: flex;

        .thumb-swiper,
        .video {
          width: 100%;
          max-width: unset;
          min-width: unset;
          height: 230px;
          margin-bottom: 16px;

          .swiper-slide {
            .slider-content {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .festival {
    margin: 0;
    background: #fff;
    border: none;

    .about-row {
      margin-bottom: 0;

      .video,
      .about-text,
      .swiper-slide {
        min-width: unset;
        max-width: unset;
        width: 100%;
      }

      .video-title {
        margin: 16px auto;
        font-size: 20px;
      }

      .about-text {
        font-size: 14px;
      }

      .video {
        margin: 0;
      }

      .thumb-swiper {
        width: 100%;
        max-width: unset;
        min-width: unset;
        height: 230px !important;
        margin-bottom: 16px;

        .mySwiper2,
        .mySwiper {
          .swiper-slide {
            .slider-content {
              padding: 0;
              flex-direction: row;
              margin-top: 20px;
              margin-left: 20px;

              img {
                width: 20%;
                height: auto;
              }

              .texts {
                .title {
                  margin-bottom: 0;
                  font-size: 16px;
                }

                .description {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .mySwiper {
          .swiper-slide {
            .slider-content {
              padding: 5px !important;
              margin: 0 !important;

              img {
                width: 20px !important;
                height: 20px !important;
                min-width: 20px;
              }

              .texts {
                margin-left: 4px;
                overflow: hidden;
                width: 100%;

                .title,
                .description {
                  font-size: 10px !important;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  line-height: 1 !important;
                  margin: 0;
                }

                .description {
                  font-size: 8px !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .festival.command {
    margin-bottom: 50px;
  }

  #why-yellow {
    .about-us {
      // margin-top: 0;
      .about-row {
        .about-title {
          margin: 0 10px;
        }

        .yellow-info-boxes {
          width: 100%;
          margin: 0 auto;

          .col-md-4 {
            padding: 0;
            margin-top: 16px;

            .box {
              .image {
                width: 70px;
                height: 70px;
                padding: 20px;
                min-width: unset;

                img {
                  width: 100%;
                }
              }

              .title-text {
                margin: 0 14px;

                .box-title {
                  margin: 0;
                  font-size: 18px;
                }

                .box-text {
                  font-size: 14px;
                }
              }
            }
          }
        }

        .about-row-swiper {
          flex-direction: column-reverse;

          .thumb-swiper {
            width: 100%;
            min-width: unset;
            max-width: unset;

            .swiper-slide {
              .slider-content {
                padding: 0;
              }
            }
          }
        }

        .yellow-benefites-boxes {
          margin-bottom: 50px;

          .info-box-title {
            font-size: 20px;
            margin: 0;
            padding: 0;
          }

          .box {
            padding: 14px;

            .image {
              width: 70px;
              height: 70px;
              padding: 20px;
              margin-bottom: 16px;
              min-width: unset;

              img {
                width: 100%;
              }
            }

            .title-text {
              .box-title {
                margin: 0;
                font-size: 16px;
              }

              .box-text {
                font-size: 14px;
                margin-bottom: 0;
              }
            }
          }
        }
      }

      .about-row-swiper {
        margin-bottom: 0;

        .thumb-swiper {
          width: 100%;
          max-width: unset;
          min-width: unset;
          height: 230px;
          margin-bottom: 16px;
        }

        .title-text {
          margin: 0;
        }

        .video-title {
          width: 100%;
          text-align: center;
          margin-bottom: 16px;
          font-size: 20px;
        }

        .text {
          p {
            margin-bottom: 4px;
          }

          font-size: 14px;
        }

        .more-button {
          position: unset;
          padding: 10px;
          height: auto;
          margin: 0 auto;
          font-size: 14px;
        }
      }
    }
  }

  #command {
    .about-us {
      .about-row {
        .about-title {
          margin: 0 10px;
        }

        .video-content {
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .big-imgage {
            margin-top: 10px;

            img {
              width: 100%;
              width: 140px;
              height: 140px;
              margin-top: 16px;
              min-width: unset;
            }
          }

          .d-none.mobile {
            display: inline-block !important;

            .video-title {
              margin: 0;
              font-size: 20px;
            }

            .job {
              font-size: 16px;
              margin: 0;
            }
          }

          .title-text {
            .video-title,
            .job {
              display: none;
            }
          }

          .about-text {
            width: 100%;
            margin: 0;
          }
        }
      }
    }
  }

  .control {
    .container > img {
      width: 80px;
      margin-bottom: 16px;
    }

    .contact-title {
      font-size: 20px;
      margin-bottom: 16px;
    }

    .contact-text {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  .contact-us-form {
    width: 100%;
    padding: 14px;

    .send-button {
      padding: 10px;
    }
  }

  .about-row-swiper .mySwiper {
    margin-bottom: 16px !important;

    .swiper-wrapper {
      height: 40px !important;
    }
  }

  #contacts {
    .contacts {
      .nav.nav-pills {
        margin-top: 30px;
        margin-bottom: 20px;
        width: auto;
        border: 1px solid #f3d127;
        padding: 6px !important;
        background: #ffffff;
        border-radius: 10px;

        .nav-item {
          margin: 0;
          padding: 0;

          &:first-child {
            .nav-link {
              margin-right: 6px;
            }
          }

          &:last-child {
            .nav-link {
              margin-left: 6px;
            }
          }

          .nav-link {
            height: auto;
            font-size: 12px;
            width: auto;
            padding: 8px;
            font-weight: 500;
          }
        }
      }

      .row {
        margin: 0 auto;

        .col-md-6 {
          padding: 0;
        }

        .tab-content {
          margin-bottom: 16px;
        }
      }

      .contacts-info-group {
        padding: 14px;

        .contacts-title {
          font-size: 20px;
          margin-bottom: 16px !important;
        }

        .contacts-info {
          padding: 14px;

          p {
            font-weight: 400;

            a {
              font-weight: 500;
              font-size: 14px;
            }
          }
        }

        .contacts-image {
          width: 60px;
          top: 28%;
        }
      }

      .contacts-address-group {
        height: 200px;
        margin-top: 16px;
        padding: 0;
        border: 1px solid #f3d127;
        padding: 14px;
        border-radius: 10px;
      }

      .contacts-form {
        min-height: unset;
        padding: 14px;
        border-radius: 10px;

        .contacts-title {
          font-size: 20px;
          margin-bottom: 16px;
        }

        .col-12 {
          padding: 0;
        }

        .col-12.mb-4 {
          margin-bottom: 16px !important;
        }

        .contacts-form-btn {
          position: relative;
          bottom: unset;
          margin: 0;
          height: auto;
          padding: 10px;
          font-size: 14px;
        }
      }
    }
  }

  #faq-content {
    .faq-content {
      margin-top: 30px;

      .faq-content-title {
        font-size: 20px;
        margin-bottom: 16px;
      }

      .accordion {
        .accordion-item {
          margin-top: 16px;

          .accordion-header {
            .accordion-button {
              border-radius: 10px;
              font-size: 14px;
              padding: 10px;
            }
          }

          .accordion-body {
            padding: 14px 14px 6px 14px;

            strong {
              font-size: 14px;
            }

            & > div {
              flex-direction: column;
              margin-top: 8px;

              p {
                width: 100%;
                font-size: 12px;
                margin-top: 0;
                margin-bottom: 10px;
              }

              a.vide {
                height: 300px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .franchize-header {
    width: 100%;

    .franchize-nav {
      width: 100%;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .fran-nav-item {
        padding: 8px;
        white-space: nowrap;
      }
    }
  }

  .franchize-section {
    margin-top: 30px;

    .franchize-top-content {
      .section-title {
        width: 100%;
        min-width: 70%;
        text-align: center;
        white-space: pre-wrap;
        font-size: 20px;
      }

      p {
        font-size: 14px;
        margin: 0;
        margin-bottom: 16px;
        text-align: center;
      }

      .video {
        width: 100%;
      }
    }
  }

  #why-choose-us {
    .why-choose-us {
      flex-direction: column;

      .section-title {
        font-size: 20px;
        text-align: center;
      }

      .why-choose-us-boxes {
        flex-wrap: wrap;

        .box {
          width: 100%;
          height: auto;
          margin: 0;
          display: flex;
          margin-bottom: 16px;
          align-items: center;
          flex-direction: column;

          .box-title {
            font-size: 16px;
            margin: 10px 0;
          }

          .box-text {
            font-size: 14px;
            margin-bottom: 0;
          }

          .box-image {
            margin: 0;
            width: 35px;
            height: 35px;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  #how-yellow-operates {
    margin-top: 10px;

    .how-yellow-operates {
      padding: 14px;
      border-radius: 20px;

      .section-title {
        font-size: 20px;
      }

      .how-yellow-operates-boxes {
        flex-wrap: wrap;

        .box {
          margin-bottom: 16px;
          height: auto;
          width: 100%;
          border-radius: 20px;
          border: 1px solid #f3d127;

          .box-title {
            font-size: 30px;
            line-height: 1;
            width: auto;
          }

          img {
            width: 30px;
            height: 30px;
            margin-bottom: 8px;
          }

          .box-text {
            position: unset;
            font-size: 14px;
          }
        }
      }
    }
  }

  #business-opportunity {
    .business-opportunity {
      .section-title {
        font-size: 20px;
      }

      .business-opportunity-boxes {
        .box {
          height: auto;
          padding: 14px;
          border-radius: 20px;
          margin-right: 16px;

          .box-sum {
            margin: 0;
            line-height: 1;
            margin-bottom: 8px;
            font-size: 20px;
          }

          .box-title {
            height: 35px;
            font-size: 14px;
            margin-bottom: 8px;
          }

          .box-text {
            font-size: 12px;
          }

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  #franchise-set-up {
    padding: 14px 0;

    .franchise-set-up {
      .section-title {
        font-size: 20px;
        text-align: center;
      }

      .franchise-set-up-boxes {
        .col-md-2 {
          padding: 0;
        }

        .box {
          background: #fff;
          border-radius: 20px;
          padding: 14px;
          width: 100%;
          text-align: center;
          margin-bottom: 16px;
          margin-top: 0;
          border: 1px solid #f3d127;

          .box-image {
            margin: 0 auto;
            width: 35px;
            height: 35px;
          }

          .box-title {
            font-size: 18px;
            margin: 8px 0;

            span {
              font-size: 20px;
            }
          }

          .box-text {
            font-size: 14px;
            margin: 0;
          }
        }
      }
    }
  }

  #faq-join-us {
    .faq-join-us {
      width: 100%;

      .section-title {
        font-size: 20px;
        margin-bottom: 0;
      }

      .accordion-item {
        margin-top: 16px;

        .accordion-header {
          .accordion-button {
            border-radius: 10px;
            font-size: 14px;
            padding: 10px;
          }
        }

        .accordion-body {
          padding: 10px;
          font-size: 12px;
          margin-top: 0;
        }
      }
    }
  }

  #featured-in {
    .featured-in {
      .section-title {
        font-size: 20px;
        text-align: center;
      }

      .featured-in-boxes {
        .col-md-3 {
          padding: 0;
        }

        .box {
          margin-bottom: 16px;
          padding: 14px;
          text-align: center;

          img {
            width: 150px;
          }
        }
      }
    }
  }

  #meet-us {
    .meet-us {
      flex-direction: column-reverse;

      .meet-us-image {
        width: 100%;
        margin-bottom: 16px;
      }

      .meet-us-content {
        width: 100%;
        margin: 0 auto;

        .section-title {
          font-size: 20px;
          margin-bottom: 16px;
          text-align: center;
        }

        p {
          font-size: 14px;
          margin-bottom: 16px;
        }

        .join-us.btn {
          display: block;
          position: unset;
          min-width: unset;
          width: fit-content;
          padding: 8px;
          height: auto;
          margin: 0 auto;
          font-size: 14px;
        }
      }
    }
  }

  #join-us {
    .join-us {
      margin-bottom: 0;

      .section-title {
        font-size: 20px;
        text-align: center;
      }

      .join-us-content {
        .left-side,
        .right-side {
          padding: 0;

          .join-us-form {
            width: 100%;
            margin: 0 auto;

            .col-md-6 {
              margin-bottom: 16px !important;
            }

            p {
              height: auto;
              font-size: 14px;
            }

            label,
            .col-12 {
              padding: 0;
              font-size: 14px;
            }

            .send-button {
              display: block;
              position: unset;
              padding: 8px;
              height: auto;
              margin: 0 auto;
              font-size: 14px;
            }
          }
        }

        .right-side {
          display: none;

          img {
            width: 50%;
            display: block;
            margin: 0 auto;
            margin-top: 40px;
          }
        }
      }
    }
  }

  #content {
    .content {
      .content-row {
        margin: 0 auto;

        .content-shop {
          padding: 0;

          .filter {
            flex-wrap: wrap;
            margin: 10px auto 20px auto;

            h6 {
              display: none;
            }
          }

          .shop-cards {
            padding: 0;

            .card-row {
              margin-top: 16px;

              .card {
                padding: 0;
                flex-direction: column;

                .card-title {
                  margin-bottom: 8px;
                  font-size: 16px;
                }

                .card-image {
                  width: 100%;
                  height: 50%;
                  background: #fff;

                  img {
                    width: 100%;
                  }

                  .card-icon {
                    z-index: 5;
                    width: 60px;
                    height: 60px;
                    right: 20px;
                    bottom: -20%;
                    top: unset;
                  }
                }

                .card-body {
                  padding: 16px;
                  margin: 0;

                  .card-text {
                    font-size: 14px;
                  }

                  .card-ratings {
                    div {
                      display: block;
                      margin-right: 20px;
                    }

                    .label {
                      margin: 0;
                      margin-bottom: 8px;
                      font-size: 12px;
                    }

                    .card-stars {
                      display: flex;

                      img {
                        margin-right: 4px;
                      }
                    }

                    .value {
                      font-size: 10px;
                      white-space: nowrap;
                      padding: 0;
                      margin-left: 4px;
                      border: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .navbar-collapse {
    position: fixed;
    overflow: hidden;
    max-width: 100vw;
    top: 0;
    border-radius: 0;
    left: 0;
    background: #ffffff;
    z-index: 20;
    right: 0;
    border: 1px solid #dadada;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .navbar-nav {
      padding: 14px 20px 20px 20px;
      .nav-item {
        margin: 0;

        .nav-link::before {
          display: none;
        }
      }
    }

    .mobile-closer {
      padding: 14px 20px;
      width: 100%;
      border-bottom: 1px solid #dadada;
      display: block !important;

      button {
        padding: 0;

        img {
          width: 25px;
        }
      }
    }
  }

  .navbar-toggler {
    span {
      font-size: 18px;
      filter: invert(0) brightness(0);
    }
  }

  #registerContent,
  #loginContent {
    .register-content,
    .login-content {
      .boxes {
        .content-right {
          padding: 14px;
          margin: 0 auto;

          .form-header-text,
          .content-right-text {
            margin: 0 auto;
            font-size: 14px;
            margin-bottom: 16px;
          }

          .content-header {
            .phoneContent {
              width: 110px;
            }

            .content-right-title {
              font-size: 20px;
              margin-bottom: 16px;
            }
          }

          .content-body {
            .row {
              margin: 0 auto;

              .input-margin-b {
                margin-bottom: 16px;
              }

              .col-12.d-flex.justify-content-between {
                margin-bottom: 0 !important;
              }

              input {
                border-radius: 10px;
                line-height: 1;
                padding: 8px;

                &::placeholder {
                  font-size: 14px;
                  border-radius: 10px;
                }
              }

              .ri-eye-off-line {
                font-size: 20px;
              }

              .login-forget-password {
                font-size: 14px;
                min-height: unset;
                height: 45px;
              }

              .btns {
                justify-content: space-between;
                margin-top: 16px !important;

                input,
                button,
                a {
                  width: max-content;
                  padding: 8px;
                  font-size: 14px;
                  margin-top: 16px;
                  height: 45px;
                  min-height: unset;
                }

                .login-btn {
                  margin-top: 0;
                }

                .social-btns {
                  margin-left: 15px;

                  img {
                    margin-top: 0;
                  }

                  a {
                    margin-top: 0;
                  }
                }
              }

              .login-btn {
                min-height: unset;
                font-size: 14px;
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  #profile {
    margin-bottom: 40px;
    margin-top: 10px;

    .profile {
      padding: 0;
    }

    #profile.profile-row {
      padding: 0 12px;
      margin: 0 auto;

      .sidebar {
        display: none;
      }

      .profile-content {
        .content-top {
          grid-gap: 14px;

          .content-top-item {
            border-radius: 10px;

            .top-item__head {
              .head-counter {
                font-size: 14px;
              }
            }
          }
        }

        .content-middle {
          margin-top: 0;
          gap: 16px;

          .content-middle-item {
            border-radius: 10px;

            &.right {
              margin-top: 16px;
            }

            .middle-item__head {
              margin: 14px;
              display: block;
            }

            .descs {
              margin: 0;
              margin-top: 16px;
            }

            .middle-item__body {
              .body-group {
                padding-top: 0;

                .body-group-top-title {
                  font-size: 20px;
                }

                .body-group__list {
                  .list-item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    align-items: center;

                    .list-item-right {
                      position: unset;
                      text-align: right;
                    }
                  }
                }
              }
            }
          }
        }

        .content-bottom {
          margin-top: 16px;
          margin-bottom: 0;

          .accordion {
            .accordion-item {
              margin-top: 16px;

              .accordion-header {
                .accordion-button {
                  border-radius: 10px;
                  font-size: 14px;
                  padding: 10px;
                }
              }

              .accordion-body {
                padding: 14px 14px 6px 14px;

                strong {
                  font-size: 14px;
                }

                p {
                  font-size: 12px;
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }

    .profile-row {
      .profile-row {
        align-items: flex-start;
        flex-direction: column;

        .item-button {
          font-size: 14px;
          padding: 8px;
          height: auto;
        }
      }

      & > .nav.nav-pills,
      .bonus-content .nav.nav-pills,
      .nav.nav-pills.top {
        // border: 1px solid #f3d127;
        // padding: 6px;
        width: max-content;
        margin-bottom: 16px !important;
        background: #ffffff;
        border-radius: 10px;

        .nav-item {
          // padding: 0;
          min-width: unset;

          .nav-link {
            margin: 0;
            white-space: nowrap;
            height: auto;
            padding: 9px;
            font-size: 13px;
          }
        }
      }

      .tab-content {
        margin: 0 auto;

        .tab-pane {
          padding: 0;

          .profile-info-group {
            padding: 14px;

            .profile-info-item {
              padding: 0;
            }

            .profile-item-group {
              .profile_title {
                font-size: 20px;
              }
            }

            .col-md-4,
            .profile-info-btn {
              padding: 0;
              margin-top: 16px;

              input {
                border-radius: 10px;
              }
            }
          }

          .profile-password-group {
            .password-item {
              border-radius: 10px;

              h1 {
                font-weight: 500;
                padding: 0;
              }

              .col-12 {
                margin-top: 16px;
                padding: 0;
              }
            }
          }
        }
      }

      .bonus-content {
        .profile-content-row {
          margin-bottom: 0 !important;
        }

        .tab-content {
          .tab-pane {
            .bonus {
              gap: 16px;

              .bonus-item {
                height: auto;

                // max-height: 140px;
                .bonus-card-right {
                  padding: 14px;
                  padding-left: 0;

                  .cart,
                  span {
                    font-size: 14px;
                    line-height: 1;
                  }

                  .bonus-title {
                    font-size: 20px;
                  }
                }
              }

              .bonus-card {
                max-height: 160px;
              }

              .bonus-invite {
                padding: 14px;

                h3 {
                  font-size: 14px;
                }

                button.send {
                  height: auto;
                  padding: 12px;
                  font-size: 14px;
                }
              }
            }

            .parcles-list {
              margin: 0 auto !important;
            }
          }
        }
      }
    }

    .parcles-content {
      .parcles-row {
        align-items: flex-start;

        .nav.nav-pills {
          max-width: 100%;
          overflow-x: auto;
        }

        .new-parcels-button-item {
          .new-parcels-button {
            font-size: 14px;
            padding: 8px;
            height: auto;
          }
        }
      }

      .tab-content {
        .tab-pane {
          .nav.nav-pills {
            margin-bottom: 16px !important;
            max-width: 100%;
            display: flex;
            align-items: center;
            min-width: unset;
            flex-wrap: nowrap;
            gap: unset;
            grid-template-columns: unset;

            .nav-item {
              margin-right: 10px;
              width: fit-content;
              min-width: unset;
              overflow: unset;

              .nav-link {
                padding: 8px;
                font-size: 12px;
                height: auto;
                border-radius: 10px !important;
              }
            }
          }

          .parcles-list {
            margin: 0;

            .table {
              overflow: hidden;
              border-radius: 10px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }

  #breadcrumbs.profile {
    display: none;
  }

  #profile-mobil-navbar.d-none {
    display: block !important;
    width: 100%;
    padding: 12px;
    position: fixed;
    bottom: 0;
    z-index: 120;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px 10px 0px 0px;

    .mobile-navbar-list {
      padding: 0;
      display: flex;
      margin: 0 auto;
      align-items: center;
      justify-content: space-around;
      list-style-type: none;
      overflow-x: auto;

      li {
        .mobile-navbar-item {
          padding: 0;
          text-decoration: none;
          color: #000;
          display: flex;
          font-size: 12px;
          flex-direction: column;
          align-items: center;
          text-align: center;
          white-space: nowrap;
          margin: 0 4px;

          i {
            font-size: 22px;
          }

          &.active {
            color: #f3d127;
          }
        }
      }
    }
  }

  #logout-modal {
    .modal-dialog {
      margin: 0 auto;
      width: auto;
    }

    .modal-content {
      .modal-body {
        padding-bottom: 16px;

        button {
          height: auto;
          padding: 8px;
          font-size: 12px;
          width: 100px !important;
        }
      }
    }
  }

  .social-networks {
    bottom: 70px;
    z-index: 100;
    right: 12px;

    .btn.social-btn {
      width: auto;
      height: auto;
      padding: 10px;

      .social-name {
        font-size: 12px;
        display: block;
        opacity: 1;
        margin-right: 10px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  #profile {
    #profile {
      .parcels-order {
        .modal-dialog-centered {
          .modal-content {
            padding: 14px;

            .modal-header {
              padding: 0;

              .modal-title {
                font-size: 18px;
                margin-bottom: 0;
              }
            }

            .modal-body {
              padding: 14px 0;

              .col.text-end.mt-3 {
                display: flex;
                justify-content: space-between;

                button {
                  min-width: unset;
                  width: 49%;
                  margin-right: 0;
                }
              }

              .parcels-steps-group {
                .parcels-modal-steps {
                  .step {
                    width: 30px;
                    height: 30px;
                    margin-right: 50px;

                    &:last-child {
                      margin-right: 0;
                    }

                    &::after,
                    &::before {
                      width: 50px;
                    }
                  }
                }

                .parcles-modal-arrows {
                  display: flex;

                  button {
                    width: 30px;
                    height: 30px;
                  }
                }
              }

              .parcels-order {
                .parcels-list-title {
                  font-size: 16px;
                }

                p {
                  font-size: 14px;
                }

                .parcels-select {
                  height: auto;
                  font-size: 14px;
                  padding: 8px 30px;
                }
              }

              .parcels-form {
                .parcels-list-title {
                  font-size: 16px;
                }

                .decloration-form {
                  .tracking,
                  .products {
                    padding: 14px 10px;

                    p {
                      font-size: 14px;
                    }

                    input {
                      margin-top: 8px;
                    }

                    .products-info {
                      margin-bottom: 8px;

                      & > .col-md-10.mb-3 {
                        width: 100%;
                        margin-bottom: 0 !important;
                      }

                      & > .w-100.d-flex.align-items-center {
                        justify-content: space-between;

                        .track-price.record-field {
                          margin-bottom: 0;
                        }
                      }

                      .product-name {
                        margin-right: 0;
                        width: 100%;
                      }

                      .track-price,
                      .track-count {
                        align-items: center;
                        width: 48%;
                        margin-right: 0;
                        margin-bottom: 8px;
                      }

                      .track-count {
                        margin-left: auto;
                      }

                      .input-btn {
                        height: 75%;
                        top: -17%;
                        right: 3px;
                        // margin-top: 8px;
                      }

                      .total {
                        margin-left: auto;
                        margin-top: 8px;
                      }
                    }

                    .new-address-btn {
                      width: 100%;
                      height: 45px;
                    }

                    textarea {
                      height: 100px;
                      font-weight: 400 !important;
                    }
                  }
                }

                .next-btn {
                  height: 45px;
                }

                .parcels-address {
                  padding: 14px;

                  .address-info {
                    p {
                      font-size: 14px;
                    }
                  }

                  .circle {
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                  }
                }

                .new-address-btn {
                  margin: 16px 0;
                }
              }

              .next-btn,
              .new-address-btn {
                height: auto;
                padding: 10px 20px;
              }
            }
          }
        }

        &.finish {
          .decloration-form {
            img {
              margin-top: 20px;
            }

            .parcels-list-title {
              width: auto;
              margin: 10px auto;
              font-size: 16px;
            }

            p {
              width: 80%;
              margin: 0 auto;
            }

            .btn.thanks {
              min-height: unset;
              height: auto;
              padding: 10px 20px;
              margin-top: 30px;
            }
          }
        }
      }

      .profile-content,
      .parcles-content > .modal > .modal-dialog {
        margin: 0 auto;
        width: 100%;

        .modal-content {
          padding: 14px;
          width: 96%;
          margin: 0 auto;

          .modal-header {
            .modal-title {
              font-size: 18px;
            }
          }

          .modal-body {
            .parcels-form {
              justify-content: space-between;

              .col-12.col-md-6 {
                margin-top: 10px;
              }

              .col-md-4 {
                width: 50%;
              }

              h6 {
                font-size: 14px;
              }

              .css-e9gkym-control,
              .css-80juum-control {
                border-radius: 14px;
                font-size: 14px;
              }

              .col-12.text-end.mt-4 {
                display: flex;
              }

              .parcels-list-declaration .item-declaration,
              .parcels-list-details {
                width: 100%;
              }
            }
          }
        }
      }

      #parcelsListViewModal {
        .modal-content {
          padding: 14px;

          .modal-title {
            font-size: 18px;
          }
        }
      }

      #parcels-edit-modal {
        .modal-title {
          margin-bottom: 0 !important;

          .parcels-list-title {
            margin-bottom: 0;
            font-size: 18px;
          }
        }

        .parcels-form {
          margin-top: 0;
        }
      }

      #service-modal {
        .modal-content {
          margin: 0;
          padding: 14px;

          .modal-header {
            .modal-title {
              font-size: 18px;
            }
          }

          .modal-body {
            padding: 0;
          }

          .modal-list {
            width: 100%;

            .modal-item {
              .form-check-input {
                width: 15px;
                height: 15px;
                min-width: 15px;
              }

              label {
                i {
                  margin-right: 10px;
                  font-size: 20px;
                }
              }

              .modal-item-title {
                font-size: 14px;
              }

              .modal-item-select {
                font-size: 12px !important;
                height: auto;
                margin-left: 0;
              }
            }
          }

          .modal-bottom {
            margin-top: 20px;
            display: block;

            div {
              width: 100%;
              display: flex;
              align-items: center;

              &:first-child {
                justify-content: space-between;
              }
            }

            p {
              font-size: 14px;
              width: auto;
            }

            button {
              margin: 0;
              margin-right: 10px;
            }
          }
        }
      }

      #paymentModal,
      #account-modal {
        .modal-dialog {
          width: 100%;
          margin: 0;

          .close.btn,
          .back {
            top: 0;
            right: 0;
          }

          .back {
            left: 0;
            position: relative;
          }

          .logo {
            display: none;
          }
        }

        .modal-content {
          padding: 14px;

          .modal-body {
            padding: 0;
            width: 100%;

            .title {
              margin-bottom: 0;
              font-size: 18px;
            }

            .balls-box {
              padding: 14px;

              .total {
                p {
                  font-size: 14px;
                }

                h4 {
                  font-size: 16px;
                }
              }
            }

            .my-accounts {
              margin-top: 0 !important;
            }

            .modal-info {
              margin: 0;

              .info-top {
                margin-top: 20px;
              }

              .info-content {
                padding: 14px;
              }

              .order-button {
                height: auto;
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }
  }

  .track {
    margin-top: 0;

    .steep-1-main {
      &.align-items-start {
        margin-bottom: 20px;
      }

      .steep-1-main-line {
        top: 42%;
      }

      .steep-1-main-item {
        & > span {
          width: 30px;
          height: 30px;
        }

        &:last-child {
          .steep-1-main-item-text {
            left: unset;
            right: 0;
          }
        }

        .steep-1-main-item-text {
          width: auto;

          h4 {
            font-size: 12px;
            line-height: 12px;
          }

          p {
            width: 100%;
            font-size: 10px;
            margin-bottom: 0;
          }
        }
      }

      .steep-1-main-item-bottom {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        white-space: nowrap;

        h5 {
          font-size: 12px;
          margin-top: 20px;
          line-height: 14px;
        }

        h3 {
          font-size: 14px;
          text-overflow: ellipsis;
          width: 95%;
          overflow: hidden;

          .tooltipText {
            right: 0;
          }
        }

        &:last-child {
          h3 {
            margin-left: auto;
          }
        }
      }
    }

    .steep-4-bottom {
      & > ul {
        width: 100%;

        & > li {
          width: 100%;
          // margin: 0 auto;
          margin-bottom: 16px;
          flex-wrap: wrap;

          .col-md-3.col-6 {
            margin-bottom: 8px;
            min-width: unset;
          }

          p {
            font-size: 12px;
            padding: 0;
            justify-content: space-between;

            &.six {
              text-align: right;
            }
          }
        }
      }
    }

    .steep-4-bottom-img {
      margin-top: 12px !important;

      .content {
        flex-direction: column;
        padding: 10px;

        p {
          padding: 0;
          margin: 0 auto;
          font-size: 14px;
          margin-bottom: 4px;
        }

        button {
          width: 100%;
        }
      }
    }
  }

  .steep-4-bottom-ul {
    .sent {
      padding-right: 12px;
    }
  }

  #loginContent {
    .login-content {
      grid-gap: unset;

      .boxes {
        width: 100%;

        .tracking-content-right {
          .track {
            margin-bottom: 0;

            .steep-4-bottom {
              padding: 0;

              ul li span.line {
                display: none;
              }
            }

            .item-dot {
              display: none;
            }
          }
        }
      }
    }
  }

  .about-row,
  .chance-row,
  .environment-row,
  .statistics-row {
    width: 100%;
    margin: 0 auto;
  }

  .claims-page {
    .parcles-content {
      h3.fw-normal {
        font-size: 22px;
      }

      .parcles-row {
        margin-bottom: 0;
      }

      .new-claim-button,
      .commit .submit-btn,
      .claim-confirm .submit-btn,
      .claim-user-info .submit-btn,
      .claim-user-info .add-btn button,
      .claim-access-modal .btn {
        font-size: 14px;
        padding: 8px;
        height: auto;
      }

      .tab-pane {
        .check-status {
          .d-flex {
            flex-wrap: wrap;

            input {
              margin-bottom: 12px;
            }

            button {
              margin-left: auto;
            }
          }
        }
      }

      &.claim-status {
        .review {
          padding: 0;
          max-width: 100%;
          width: 100%;

          .commit {
            textarea {
              width: 100%;
            }
          }
        }
      }

      .claim-selector {
        select {
          max-width: 100%;
        }
      }

      .claim-user-info {
        .col-md-6,
        .col-md-6.last {
          h6 {
            margin-left: 0;
            font-weight: 600 !important;
          }

          .fields {
            border: none;

            .col-12 {
              margin-left: 0;
              width: 100%;
            }
          }
        }

        .submit-btn {
          margin: 0 0 0 auto;
        }

        .products {
          .fields {
            border-bottom: 1px solid #eee;
            padding-bottom: 30px;
            margin-bottom: 30px !important;
            flex-wrap: wrap;

            .field {
              width: 48%;
              margin-bottom: 16px;
            }

            &.labels {
              display: none;
            }
          }

          & + .d-flex {
            margin: 0 0 30px 0 !important;
            justify-content: space-between;
          }
        }

        form {
          .pictures-list {
            margin-bottom: 20px;
          }

          .pictures {
            height: 100px;
            margin-bottom: 30px;
          }
        }
      }

      .claim-confirm {
        h3.fw-normal.mt-2,
        h4.fw-normal.mt-4 {
          font-size: 18px;
        }

        .row {
          .col-md-6:last-child {
            h5 {
              margin-top: 30px;
            }
          }

          .col-md-7.col-12 {
            margin-bottom: 30px;
          }

          &.mb-5 {
            margin-bottom: 30px !important;
          }
        }

        .mb-5.col-12.mt-5 {
          margin: 30px 0 !important;
        }

        .offset-md-1.col-12 {
          text-align: left;
        }

        .product-info {
          h6 {
            font-size: 14px;
          }

          span {
            font-size: 12px;
            margin: 10px 0 0;
          }
        }

        & > .submit-btn {
          margin: 0 0 0 auto;
        }

        &.confirmed {
          h3,
          h2 {
            font-size: 22px;
          }

          .claim-info {
            width: 90%;

            span,
            a {
              font-size: 14px;
            }

            .submit-btn {
              margin: 30px auto;
            }
          }
        }
      }
    }
  }
}

.track {
  .steep-4-bottom-img {
    .content {
      flex-direction: column;

      p {
        padding: 0;
        font-size: 14px;
        margin-bottom: 4px;
      }

      button {
        width: 100%;
      }
    }
  }
}

@media (max-width: 991px) {
  .box-image .modal-content {
    padding: 10px;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  .steep-4-bottom-img {
    .rew {
      padding: 0;
      margin-bottom: 10px;
    }

    .sig {
      padding-left: 0;
    }
  }

  .only-mobile {
    display: inline-block;
  }

  .track {
    .steep-1-bottom {
      display: flex;
      align-items: flex-start;

      .thead,
      .tbody {
        width: 50%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 10px;

        &.thead {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          padding-left: 0;
        }

        &.tbody {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          padding-right: 0;

          p {
            text-align: right;
          }
        }

        p {
          padding: 5px 0;
          font-size: 12px;
          height: 40px;
          width: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;

          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-spacing: normals;
        }
      }
    }
  }
}

.edit-content {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.1);
  top: 0;
  left: 0;
  display: flex;
  transition: 0.3s;
  align-items: center;
  justify-content: center;
  user-select: none;
  border-radius: 10px;
  overflow: hidden;

  button {
    margin-left: 0 !important;

    i {
      color: #fff;
    }
  }

  .btn.delete {
    background: rgba(229, 9, 49, 0.5);

    &:hover {
      background: rgba(229, 9, 49, 1);
    }
  }

  .create-btn {
    background: rgba(33, 130, 243, 0.5);
    border-radius: 50%;
    margin-left: 5px !important;

    &,
    i {
      transition: 0.3s;
    }
  }

  .create-btn:hover {
    background: rgba(33, 130, 243, 1);
  }
}

.buttons {
  position: relative;

  &:hover {
    .edit-content {
      opacity: 1;
    }
  }
}

.social-networks {
  opacity: 0;
  animation-name: netAnimation;
  animation-delay: 3s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes netAnimation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 450px) {
  .swipers
    .swiper-wrapper
    .swiper-slide
    .swiper-content
    .swiper-content-texts
    .text {
    font-size: 10px;
  }

  .swipers {
    .swiper-content {
      .swiper-content-texts {
        .swiper-title {
          flex-direction: column;
          align-items: flex-start;

          .text {
            display: none;
          }
        }
      }
    }
  }
}

#how-work {
  .video-preview {
    img {
      max-width: 770px;
      margin-right: 20px;
    }
  }
}

input {
  border-color: #f4f4f4;
}

hr:not([size]) {
  background-color: #999999;
}

.info-modal {
  .modal-content {
    padding: 20px 30px 30px;
  }

  @media (max-width: 991px) {
    .modal-content {
      padding: 20px;
    }
  }
}

.invoice-modal {
  .modal-header {
    padding: 10px 10px 0 10px !important;
  }
}

@media (max-width: 1200px) and (min-width: 991px) {
  #about .about-text,
  #how .how-text,
  #environment .environment-text,
  #help .help-text,
  #service .service-text,
  #commentSwiper .comment-text,
  #meet-us .meet-us-text {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  #service .service-text {
    -webkit-line-clamp: 10;
  }

  .left-side {
    .join-us-form {
      .radio-buttons {
        p {
          white-space: nowrap;
        }
      }
    }
  }
}

.terms-page {
  .section-content {
    margin: 0 auto;
    max-width: 1000px;

    p {
      font-size: 16px;
      font-variant-numeric: normal;
      font-variant-east-asian: normal;
      vertical-align: baseline;
      white-space: pre-wrap;
    }

    @media (max-width: 991px) {
      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 400px) {
  .track {
    .steep-1-main {
      .steep-1-main-item {
        .steep-1-main-item-text {
          h4 {
            font-size: 10px;
          }

          p,
          span {
            font-size: 8px;
          }
        }
      }

      .steep-1-main-item-bottom {
        h3 {
          font-size: 12px;
        }
      }
    }

    .steep-4-bottom {
      & > ul {
        & > li {
          p {
            font-size: 10px;
          }
        }
      }
    }

    .steep-4-bottom-img {
      .content {
        p {
          font-size: 12px;
        }

        button {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 330px) {
  .track {
    .steep-1-main {
      margin-bottom: 20px;

      .steep-1-main-item {
        .steep-1-main-item-text {
          p,
          span {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  #paymentModal {
    .plan-m.modal-dialog {
      width: auto;
      max-width: 100%;
    }

    // .modal-dialog{
    //   max-width: 100%;
    //   width: 100%;
    //   .modal-content{
    //     // width: 90%;
    //     margin: 0 auto;
    //   }
    // }
  }

  .subscription-page {
    & ~ #paymentModal {
      .modal-dialog {
        max-width: 100%;
        width: 100%;
        margin: 0;
      }
    }
  }
}

#header-nav {
  .only-desktop {
    display: none;
  }

  .nav-link.btn-profile-icon {
    display: none;

    @media (max-width: 991px) {
      display: block;
    }
  }
}

.modal-body.photo ul li,
.box-image .modal-body.photo {
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    .btn-show {
      opacity: 1;
    }
  }

  div {
    z-index: 3;
  }

  .btn-show {
    opacity: 0;
    transition: 0.3s;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    background: #fff;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);

    i {
      color: #f3d127;
    }
  }
}

.steep-4-bottom-ul {
  .sent {
    padding-right: 22px;
  }
}

.error-border {
  div[class$="-control"] {
    border-color: #e83d3d !important;
  }
}

.yellow-center {
  margin: 100px auto;

  .center-box {
    position: relative;
    height: 500px;

    .responsive-iframe {
      border: 1px solid #f3d127;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 991px) {
    margin: 40px auto;

    .center-box {
      height: 300px;
      margin-bottom: 20px;
    }
  }
}

.close.btn {
  img {
    transition: 0.2s;
  }

  &:hover {
    img {
      filter: invert(90%) sepia(16%) saturate(4040%) brightness(111%)
        contrast(91%);
    }
  }
}

#paymentModal {
  .plan-m.modal-dialog {
    .modal-content .modal-info .title {
      border-bottom: 1px solid #f3d127;
      margin: 0 auto;
      width: fit-content;
    }
  }
}

@media (min-width: 1600px) {
  .steep-1-main-item-text {
    width: max-content;
  }
}

#parcels-edit-modal {
  .modal-dialog {
    width: 1000px;
  }
}

.btn.back {
  transition: 0.2s;

  img {
    transition: 0.2s;
  }

  &:hover {
    color: #f3d127;

    img {
      filter: invert(90%) sepia(16%) saturate(4040%) brightness(111%)
        contrast(91%);
    }
  }
}

.add-address-modal {
  .modal-dialog {
    max-width: unset;
    width: 100%;

    .modal-content {
      max-width: 850px;
      margin: 0 auto;
    }
  }
}

.estemate-time {
  margin-bottom: 40px;

  h6 {
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid #f3d127;
  }

  p {
    text-align: right;
    border-bottom: 1px solid #f3d127;
    font-size: 14px;
    font-weight: 600;
  }

  @media (max-width: 991px) {
    margin-bottom: 20px;

    h6,
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.address-item {
}

.page-pagination {
  margin: 16px 0 0 0;

  ul {
    padding: 0;
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-end;
    list-style: none;

    li {
      a {
        margin: 8px;
        border-radius: 10px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #000;
        border: 1px solid #f3d127;
        text-decoration: none;
        transition: 0.3s;

        &:hover {
          background: #f3d127;
        }
      }
    }

    li.active {
      a {
        background: #f3d127;
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .page-pagination {
    ul {
      li {
        a {
          margin: 6px;
          border-radius: 6px;
          width: 35px;
          height: 35px;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

#special-page {
  #navbar {
    padding: 16px 0;
  }

  .navbar-brand {
    margin: 0 auto;
    padding: 0;
  }

  .about-row-swiper {
    position: relative;
    height: auto;
    max-width: 900px;
    margin: 0 auto;

    .mySwiper2-prew-btn,
    .mySwiper2-next-btn {
      top: 110px;
    }
  }

  .swipers {
    margin-top: 60px;
    height: auto;
    max-width: 900px;

    .content {
      text-align: center;

      h2 {
        font-weight: 600;
        font-size: 38px;
        line-height: 50px;
        color: #121212;
        margin-bottom: 22px;
      }

      p {
        font-weight: 510;
        font-size: 16px;
        line-height: 145%;
        color: #121212;
      }
    }

    .swiper-image {
      border: none;
      width: 300px;
      margin: 0 auto;
    }
  }

  #registerContent {
    background: unset;
    min-height: auto;

    .register-content {
      background: none;
      grid-template-columns: auto;

      &::before,
      &::after {
        display: none;
      }

      .content-left {
        display: none;
      }
    }

    .boxes {
      height: auto;
      padding-bottom: 50px;

      .content-right {
        margin: 0 auto;
        position: relative;
        max-width: 612px;
        top: unset;
        transform: unset;
        left: unset;
        box-shadow: 0px 14px 20px rgba(194, 194, 194, 0.25);
        border-radius: 20px;

        .content-header {
          display: none;
        }
      }
    }
  }

  .buy-from {
    max-width: 1100px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 150px;

    h2 {
      font-weight: 600;
      font-size: 38px;
      line-height: 50px;
      text-align: center;
      margin-bottom: 60px;
    }

    .steep-1-main {
      max-width: 1000px;
      margin-bottom: 32px;

      .steep-1-main-line {
        top: 30px;
        background: #f4f4f4;
        width: calc(100% - 200px);
      }

      .steep-1-main-item {
        span {
          background: #fff;
          border: 1px solid #f3d127;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          line-height: 24px;
          color: #f3d127;
          margin-bottom: 20px;
        }

        .steep-1-main-item-text {
          max-width: 200px;
          width: auto;
          position: relative;
          left: unset;
        }
      }
    }

    .swiper-button.btn {
      border: 1px solid #f3d127;
      border-radius: 8px;
      padding: 15px 38px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      transition: 0.3s ease;

      &:hover {
        background: #f3d127;
      }
    }
  }

  #calculator-new {
    margin-bottom: 150px;
  }

  .products {
    margin-bottom: 200px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 40px;

      h2 {
        font-weight: 600;
        font-size: 38px;
        line-height: 50px;
        color: #121212;
        margin-bottom: 0;
      }
    }

    .product-list {
      margin: 0 auto;

      .product-card {
        margin-bottom: 70px;
        text-decoration: none;
        transition: 0.3s all;
        display: block;

        .product-header {
          border-radius: 20px;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            object-fit: cover;
          }

          span {
            position: absolute;
            bottom: 16px;
            left: 16px;
            background: #f3d127;
            font-weight: 590;
            font-size: 14px;
            line-height: 17px;
            padding: 10px 12px;
            border-radius: 50px;
            color: #000;
          }
        }

        .product-body {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          position: relative;

          .name-button {
            .name {
              opacity: 1;
              transition: 0.3s all;
            }

            h4 {
              font-weight: 510;
              font-size: 16px;
              line-height: 21px;
              color: #121212;
              margin-bottom: 0;
            }

            span {
              font-weight: 400;
              font-size: 13px;
              line-height: 17px;
              color: #848484;
            }

            button {
              position: absolute;
              top: 50%;
              z-index: 2;
              transform: translateY(-50%);
              left: 0;
              border-radius: 8px;
              padding: 14px 50px;
              font-weight: 600;
              background: #f3d127;
              transition: 0.3s all;
              font-size: 16px;
              line-height: 19px;
              color: #000;
              opacity: 0;
            }
          }

          .price {
            text-align: right;

            h5 {
              font-size: 18px;
              line-height: 24px;
              color: #121212;
              margin-bottom: 0;
            }

            span {
              text-decoration: line-through;
              color: #e8c305;
              font-size: 14px;
              line-height: 19px;
            }
          }
        }

        &:hover {
          .product-body {
            .name-button {
              .name {
                opacity: 0;
              }

              button {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: center;

      button:first-child {
        margin-right: 10px;
        width: 170px;
      }
    }

    .btn {
      border: 1px solid #f3d127;
      border-radius: 8px;
      padding: 15px 38px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      transition: 0.3s ease;

      &:hover {
        background: #f3d127;
      }
    }
  }

  #brands {
    margin-bottom: 70px;

    h2 {
      font-weight: 600;
      font-size: 44px;
      line-height: 56px;
      color: #121212;
    }

    .row {
      margin: 0 auto;

      .brand-image {
        border-radius: 50%;
        border: 1px solid #f3d127;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        height: 310px;
        width: 310px;
        margin-bottom: 25px;

        img {
          object-fit: contain;
          transition: 0.3s all;
        }

        &:hover {
          cursor: pointer;

          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  #services {
    padding: 60px 0;
    background: #f4f4f4;

    .row {
      margin: 0 auto;
    }

    .top-side {
      margin-bottom: 60px;

      .left-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .top-content {
          .title {
            font-weight: 600;
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 20px;
          }

          .text {
            width: 80%;
            font-size: 16px;
            line-height: 20px;
            color: #848484;
          }
        }

        .bottom-content {
          ul {
            list-style: decimal;
            padding-left: 18px;
          }

          p,
          li {
            &::marker {
              position: relative;
            }

            font-weight: 500;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: -0.02em;
            margin-bottom: 0;

            a {
              color: #f3d127;
            }
          }
        }
      }

      .service-image {
        border-radius: 20px;
        overflow: hidden;

        img {
          object-fit: cover;
          width: 100%;
        }
      }
    }

    .bottom-side {
      .box {
        border-radius: 20px;
        border: 1px solid #f3d127;
        padding: 40px;
        height: 410px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;

        img {
          max-width: 70px;
        }

        .box-content {
          position: relative;
        }

        p {
          font-weight: 550;
          font-size: 26px;
          line-height: 30px;
          margin-bottom: 10px;
          margin-top: 40px;
          position: absolute;
          transition: 0.5s all;
          bottom: 0;
        }

        span {
          font-size: 14px;
          line-height: 16px;
          color: #121212;
          transition: 0.5s all;
          opacity: 0;
          display: block;
          position: absolute;
          transform: translateY(100%);
        }

        &:hover {
          cursor: pointer;

          p {
            position: relative;
          }

          span {
            position: relative;
            opacity: 1;
            transform: none;
          }
        }
      }
    }

    .btn {
      border: 1px solid #f3d127;
      border-radius: 8px;
      padding: 15px 38px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      transition: 0.3s ease;
      margin: 0 auto;
      background: #f3d127;
      display: table;
      margin-top: 40px;

      &:hover {
        background: #fff;
      }
    }
  }

  #how-work {
    margin-top: 100px;
    margin-bottom: 100px;

    .container {
      position: relative;

      .video {
        position: absolute;
        left: 0;
        top: 200px;

        img {
          max-height: 231px;
          max-width: 231;
          border-radius: 50%;
        }

        p {
          margin-top: 10px;
          font-size: 16px;
          line-height: 19px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #000000;
        }
      }
    }

    .row {
      margin: 0 auto;
      text-align: center;
    }

    .header {
      h2 {
        font-weight: 550;
        font-size: 38px;
        line-height: 50px;
        margin-bottom: 20px;
      }

      p {
        margin: 0 auto;
        font-size: 16px;
        line-height: 21px;
        margin-bottom: 90px;
      }
    }

    .header,
    .section-body {
      max-width: 1000px;

      .step {
        .number {
          font-weight: 700;
          font-size: 16px;
          line-height: 15px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #e8c305;
        }

        .line {
          position: relative;
          margin: 10px auto;
          background: #c4c4c4;
          height: 80px;
          width: 1px;

          &::before {
            content: "";
            width: 11px;
            height: 11px;
            background: #e8c305;
            border-radius: 50%;
            position: absolute;
            bottom: 0;
            transform: translateX(-50%);
            left: 50%;
            right: 0;
          }
        }

        img {
          width: 100%;
          max-width: 490px;
        }

        &:first-child {
          p {
            margin-bottom: 20px;
          }

          .line {
            height: 0;
          }
        }
      }
    }
  }

  #walk {
    .row {
      margin: 0 auto;

      .content {
        margin-top: 40px;
        padding: 0;

        img {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .about-row-swiper {
      .mySwiper2-prew-btn,
      .mySwiper2-next-btn {
        top: 80px;
        width: 40px;
        height: 40px;

        i {
          width: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 16px;
          margin: 0 auto;
        }
      }
    }

    .swipers {
      margin-top: 55px;

      .content {
        padding: 0;

        h2 {
          font-size: 20px;
          line-height: 24px;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          line-height: 145%;

          @media (max-width: 568px) {
            max-width: 300px;
            margin: 0 auto;
            font-weight: normal;
            margin-bottom: 10px;
          }
        }
      }

      .swiper-image {
        border: none;
        width: 300px;
      }
    }

    #registerContent {
      padding: 0;

      .boxes {
        padding: 0 20px 40px;

        .content-right {
          box-shadow: 0px 14px 20px 0px rgba(194, 194, 194, 0.25);

          .content-body {
            .btns {
              display: block;

              .login-btn.btn {
                width: 100%;
              }

              .social-btns {
                margin-left: 0;
                margin-top: 20px;
              }
            }
          }
        }
      }
    }

    .buy-from {
      display: none;
      margin-top: 30px;
      margin-bottom: 80px;
      width: 100%;

      h2 {
        font-size: 20px;
        line-height: 1;
        margin-bottom: 20px;
        text-align: left;
      }

      .steep-1-main {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 0;

        .steep-1-main-item {
          display: flex;
          align-items: center;
          margin-bottom: 70px;

          span {
            margin: 0;
            margin-right: 12px;
          }

          h4 {
            font-size: 16px;
            font-weight: normal;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }

        .steep-1-main-line {
          width: 2px;
          height: 98%;
          top: 50%;
          transform: translateY(-50%);
          left: 30px;
        }
      }
    }

    #calculator-new {
      margin-bottom: 80px;
    }

    .products {
      margin-bottom: 80px;

      .title {
        display: block;

        h2 {
          font-size: 20px;
          line-height: 1;
          margin-bottom: 20px;
        }
      }
    }

    #brands {
      margin-bottom: 80px;

      h2 {
        font-size: 20px;
        text-align: center;
        line-height: 1;
        margin-bottom: 20px;
      }

      .brand-image {
        margin: 0 auto;
      }
    }

    #services {
      background: #fff;

      .top-side {
        .left-side {
          .top-content {
            .title {
              text-align: center;
              line-height: 26px;
              font-size: 20px;
            }

            .text {
              width: 100%;
              text-align: center;
              font-size: 14px;
            }
          }

          .bottom-content {
            margin-bottom: 30px;

            p,
            li::marker {
              font-size: 14px;
            }
          }
        }

        .service-image {
          height: 586px;

          img {
            height: 100%;
          }
        }
      }

      .bottom-side {
        .box {
          height: 350px;
          padding: 40px 20px;
          margin-bottom: 10px;

          .box-image {
            width: 60px;
            height: 60px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .box-content {
            p {
              font-size: 20px;
            }
          }
        }
      }

      a.btn {
        margin-top: 10px;
        width: 100%;
      }
    }

    #how-work {
      margin: 20px auto;

      .container {
        .video {
          position: relative;
          top: unset;
          margin-bottom: 42px;
        }

        .header {
          h2 {
            margin-bottom: 12px;
            line-height: 1;
            font-size: 20px;
          }

          p {
            font-size: 14px;
            margin-bottom: 20px;
          }
        }

        .section-body {
          .text,
          .step:first-child .text {
            margin-bottom: 10px;
            font-size: 14px;
          }

          .step:first-child .text {
            margin-top: 30px;
          }
        }
      }
    }

    #walk {
      .content {
        height: 600px;

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    #contacsUs {
      .contact-us {
        margin-top: 0;
      }
    }
  }
}
