.review {
  border-radius: 20px;
  padding: 10px;
  height: 100%;
  margin: 0 auto;

  .set-review {
    margin-bottom: 20px;

    button {
      padding: 0;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

  h5 {
    padding: 0;
    font-size: 14px;
    margin-bottom: 4px;
    font-weight: normal;
  }

  #review-modal {
    .review-modal {
      .modal-header {
        padding: 0;

        img {
          width: 20px;
        }
      }

      .modal-body {
        padding: 0;

        .contacts-form {
          margin: 0;
          margin-top: 10px;
          padding: 10px;
          min-height: unset;

          .contacts-title {
            display: none;
          }

          .contacts-form-btn {
            position: relative;
            height: 45px;
            bottom: unset;
          }

          .dropdown-menu {
            li {
              margin: 0;
            }
          }

          .finished {
            img {
              margin: 0 auto;
              width: 54px;
              display: block;
              margin-top: 10px;
            }

            .parcels-order.show.finish .modal-dialog .modal-title {
              margin: 0;
            }

            h3 {
              text-align: center;
              margin: 0 auto;
              margin-top: 20px;
            }

            .thanks {
              background-color: #fff;
              min-width: 160px;
              min-height: 50px;
              border: 1px solid #f3d127;
              border-radius: 10px;
              font-weight: 500;
              font-size: 14px;
              padding: 10px 50px;
              margin: 0 auto;
              display: block;
              color: #000000;
              margin-top: 30px;
              transition: 0.5s all;
              margin-bottom: 10px;
            }

            .thanks:hover {
              background-color: #f3d127;
            }

            // img {
            //     margin-top: 20px;
            //   }
            //   .parcels-list-title {
            //     width: auto;
            //     margin: 10px auto;
            //     font-size: 16px;
            //   }
            //   p {
            //     width: 80%;
            //     margin: 0 auto;
            //   }
            //   .btn.thanks {
            //     min-height: unset;
            //     height: auto;
            //     padding: 10px 20px;
            //     margin-top: 30px;
            //   }
          }
        }
      }
    }

    @media (max-width: 991px) {
      .review-modal {
        .modal-content {
          padding: 10px;
        }

        .modal-header {
          padding: 0;
        }

        .modal-body {
          padding: 0;

          .contacts-form {
            margin: 0;
            margin-top: 10px;
            padding: 10px;
            min-height: unset;

            .contacts-title {
              display: none;
            }

            .contacts-form-btn {
              position: relative;
              height: 45px;
              bottom: unset;
            }
          }
        }
      }
    }
  }

  @media (max-width: 991px) {
    .set-review {
      margin-bottom: 0;

      h5 {
        font-size: 12px;
        margin-bottom: 0;
      }

      button {
        margin-right: 6px;
        width: 25px;
        height: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img {
          width: unset;
          height: unset;
        }
      }
    }
  }
}


.comment-modal {
  max-width: max-content;

  .contact-us-form {
    text-align: center;

    img {
      margin-bottom: 20px;
    }
  }

  @media(max-width: 991px) {
    max-width: unset;
  }
}